import { apiEndpoint, APIResult, delay } from "./config";

async function callAPI(body, target, defaultReturn) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      apikey: "whattowe@rlehidontknow",
    },
    body: JSON.stringify(body),
  };

  debugPrint(target);
  debugPrint(body);

  var proceed = true;
  Object.values(body).forEach(param => {
    if (param === null || param === undefined) {
      proceed = false;
    }
  });

  if (proceed) {
    await new Promise(resolve => setTimeout(resolve, delay));
    return await fetch(target, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        debugPrint(responseData);
        try {
        } catch (e) {
          console.log(e);
          return defaultReturn;
        }
        return responseData;
      })
      .catch(error => {
        console.warn(error);
        return defaultReturn;
      });
  } else {
    return defaultReturn;
  }
}

export async function login(username, password) {
  var body = {
    u: username,
    p: password,
  };

  var target = apiEndpoint + "dashboard/login";

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "TIME OUT",
  });
}

export async function getResponseData(id) {
  var body = {
    id: id,
    jwt: localStorage.getItem("j"),
  };

  var target = apiEndpoint + "dashboard/get_response_data";

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "TIME OUT",
  });
}

export async function surveyInsert(id, surveyData) {
  var body = {
    id: id,
    survey_data: surveyData,
  };

  var target = apiEndpoint + "survey/survey_insert_v2";

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "TIME OUT",
  });
}

export async function getResponseList(
  page,
  itemPerPage,
  transactionType,
  markDone,
  responseID
) {
  var body = {
    page: page,
    item_per_page: itemPerPage,
    transaction_type: transactionType,
    mark_done: markDone,
    response_id: responseID,
    jwt: localStorage.getItem("j"),
  };

  var target = apiEndpoint + "dashboard/get_response_list";

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "TIME OUT",
  });
}

export async function insertCatalogueItems(id, items) {
  var body = {
    id: id,
    items: items,
    jwt: localStorage.getItem("j"),
  };

  var target = apiEndpoint + "dashboard/insert_catalogue_items";

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "TIME OUT",
  });
}

export async function manualPay(id) {
  var body = {
    id: id,
    jwt: localStorage.getItem("j"),
  };

  var target = apiEndpoint + "dashboard/manual_pay";

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "TIME OUT",
  });
}

export async function getCatalogueItems(id) {
  var body = {
    id: id,
  };

  var target = apiEndpoint + "survey/catalogue_get_items_data";

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "TIME OUT",
  });
}

export async function cataloguePay(
  id,
  buyItems,
  name,
  phone,
  ic,
  email,
  address
) {
  var body = {
    id: id,
    buy_items: buyItems,
    name: name,
    phone: phone,
    ic: ic,
    email: email,
    address: address,
  };

  var target = apiEndpoint + "survey/catalogue_pay";

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "TIME OUT",
  });
}

export async function getItemsData(id) {
  var body = {
    id: id,
    jwt: localStorage.getItem("j"),
  };

  var target = apiEndpoint + "dashboard/get_items_data";

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "TIME OUT",
  });
}

export function generateID(length) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

function debugPrint(s) {
  console.log(s);
}
