import React from "react";
import queryString from "query-string";
import "./ThankYouPage.css";
import "./common.css";
import Logo from "../Images/wtw_logo.png";
import ExpandableFaq from "../Components/ExpandableFaq";
import Footer from "../Components/Footer";
import withRouter from "../Utilities/withRouter";

class ThankYouPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reference: "",
      mobileMode: false,
    };

    this.faqs = [
      {
        q: "What Is Try-At-Home?",
        a: "TRY-AT-HOME is a Try First, Pay Later service where we let our customers physically try our collections at the comfort of their homes before purchasing them. Our designers will hand-pick a few styles based on the customers’ profiles, and we’ll have them delivered to their door without any courier charges. They may then decide to purchase any of the items and return the rest.<br /><br />Through this service, we get to help our customers who want to look stylish in quality garments by giving them ample time to try and decide on the outfits and we will also provide a few styling tips.",
      },
      {
        q: "HOW DOES THE TRY-AT-HOME SERVICE WORK?",
        a:
          "Step 1 – Fill up the provided form and let us find your style.\nStep 2 – Items t" +
          "hat have been selected will be delivered to you.\nStep 3 – Try on the items. Kee" +
          "p what you like and return the rest within <b>seven (7) days</b>.\nStep 4 – Cont" +
          "act us and we will arrange the pickup. Complete your order and make your payment" +
          ".",
      },
      {
        q: "WHERE IS THIS SERVICE AVAILABLE?",
        a:
          "This service is currently available to customers with a billing address register" +
          "ed in West Malaysia only.",
      },
      {
        q: "Is this a subscription model?",
        a: "No subscription is required. You can use Try at Home by What to Wear as frequently as you want to, whenever you want to.",
      },
      {
        q: "HOW DO I PAY?",
        a: "We currently accept online banking, FPX, Credit card. Our customer services team will follow up with you via WhatsApp within 24 hours after form submission. They will guide you thru the checkout process.",
      },
      {
        q: "IS THE TRY-AT-HOME SERVICE VALUE FOR MONEY?",
        a: "Worth every penny! Because there will be an exclusive discount if you buy all items, and we will also have a promotion from time to time.",
      },
      {
        q: "HOW LONG DOES IT TAKE FOR MY TRY-AT-HOME PARCEL TO ARRIVE?",
        a: "Parcel takes about 3-5 days for processing and 1-3 business days to ship. Once they have been shipped out, you will receive a notification via WhatsApp or email with tracking information so you can know exactly when to expect your parcel!",
      },
      {
        q: "WHAT HAPPENS AFTER I’VE DONE TRYING?",
        a: "If you decide on a style, you can keep the one you love and return the rest. Contact us and fill in the return form to inform your final order and we will arrange for free reverse pickup.",
      },
      {
        q: "THE STYLES I TRIED ON DIDN’T WORK OUT FOR ME. CAN I ORDER A SECOND TRY-AT-HOME?",
        a: "Yes absolutely! If you’re looking to try on a new round of completely different styles, send us back the parcel from your first try-on and contact us to arrange another TRY-AT-HOME service.",
      },
      {
        q: "HOW DO I SEND THE PARCEL BACK?",
        a: "After having fun trying on your items for <b>seven (7) days</b>, put back the items that you want to return in the original packaging. Contact us and we will arrange for the pickup.",
      },
      {
        q: "WHAT IS THE RETURNS POLICY AFTER A PURCHASE IS MADE?",
        a: "Any items purchased through the TRY-AT-HOME service are not eligible for returns. Please contact our customer services team via WhatsApp or email if you have any queries about your purchase.",
      },
    ];

    this.share = this.share.bind(this);
  }

  share() {
    const shareData = {
      title: "What-To-Wear TRY-AT-HOME",
      text: "What-To-Wear TRY-AT-HOME - TRY FIRST, PAY LATER",
      url: "https://try.whattowear.com.my",
    };
    navigator.share(shareData);
  }
  handleResize = e => {
    let mobileMode = window.innerWidth < 650;
    this.setState({ mobileMode: mobileMode });
  };
  componentDidMount() {
    let urlParams = queryString.parse(this.props.router.location.search);
    if ("r" in urlParams) {
      this.setState({ reference: urlParams.r });
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    var r = urlParams.r;
    if (r.includes("TRYS")) {
      window.sendTracking("enter", "surveydone", urlParams.r);
    }

    window.fb_pixel_track_complete(urlParams.r);
  }

  render() {
    return (
      <div className="parent">
        <div className="center">
          <div className="content centric">
            <a href="/">
              <img className="logo" src={Logo} alt="logo" />{" "}
              {this.state.reference !== "" ? (
                <div className="reference-parent">
                  Reference number:
                  <div className="bold">{` ${this.state.reference}`}</div>
                </div>
              ) : null}
            </a>
            {/*<img className="thankyou margin-top-m" src={ThankYou} alt="thankyou"/>*/}
            <div className="big-display-title">THANK YOU!</div>
            <div className="mid-display-title">
              WE WILL UPDATE YOU ON PROGRESS
            </div>
            {this.state.mobileMode ? (
              <div className="margin-bot">
                <div
                  className="black-button"
                  onClick={() => {
                    this.share();
                  }}>
                  SHARE
                </div>
              </div>
            ) : null}

            <div className="faq-parent margin-top-l">
              <div className="faq-title">F.A.Q</div>
              {this.faqs.map((eachFaq, index) => (
                <ExpandableFaq
                  key={`faq-${index}`}
                  question={eachFaq.q.toLowerCase()}
                  answer={eachFaq.a}
                />
              ))}
            </div>
            <div className="display-title query-text margin-top-l">
              Still have questions?
            </div>
            <div className="display-title query-text">
              Kindly WhatsApp us at
            </div>
            <div className="display-title contact-text margin-top-xs">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://api.whatsapp.com/send/?phone=60136838383">
                +6013-683 8383
              </a>
            </div>
            <div className="display-title query-text margin-top-s">
              or email
            </div>
            <div className="display-title query-text margin-top-xs">
              hello@whattowear.com.my
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ThankYouPage);
