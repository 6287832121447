import React from "react";
import queryString from "query-string";
import "./ReplyResponsePage.css";
import Logo from "../Images/wtw_logo.png";
import {
  getResponseData,
  generateID,
  insertCatalogueItems,
  manualPay,
} from "../Utilities/Requests";
import NewItem from "../Components/NewItem";
import { TailSpin } from "react-loader-spinner";
import CartItem from "../Components/CartItem";
import withRouter from "../Utilities/withRouter";

class ReplyResponsePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address: "",
      body_type: "",
      bust: "",
      dob: "",
      email: "",
      fb_id: "",
      ig_id: "",
      name: "",
      nric: "",
      occupation: "",
      phone: "",
      response_time: "",
      shoulder: 0,
      size_bottom: "M",
      size_top: "M",
      skin_tone: "Fair",
      style_pref: "Hijab",
      thigh: 0,
      underbust: 0,
      waist: 0,
      weight: 0,
      height: 0,
      hips: 0,
      items: [],
      loadedItems: [],
      loading: true,
      doubleConfirm: false,
      doubleConfirmManualTransfer: false,
      message: 0,
      readOnly: false,
      response_id: "",
      transaction_ref: "",
      catalogue_pay_transaction_ref: "",
      catalogue_pay_transaction_time: "-",
      never_wear_color: "",
      design: "",
      wish_to_receive: "",
      work_casual: "",
      portrait: [],
      pattern: "",
      current_outfit: "",
      top_preference: "",
      sleeve_preference: "",
      skirt_preference: "",
      dress_preference: "",
      pants_preference: "",
      jeans_preference: "",
    };

    this.getResponseInfo = this.getResponseInfo.bind(this);

    this.deleteLoadedItem = this.deleteLoadedItem.bind(this);

    this.deleteItem = this.deleteItem.bind(this);

    this.addItem = this.addItem.bind(this);

    this.sendData = this.sendData.bind(this);

    this.sendManualTransfer = this.sendManualTransfer.bind(this);
    this.isItPaid = this.isItPaid.bind(this);

    this.allItemRefs = [];
    this.allLoadedItemRefs = [];
    this.id = "";
    this.paidItemsID = [];
  }

  componentDidMount() {
    this.getResponseInfo();
  }

  async getResponseInfo() {
    let urlParams = queryString.parse(this.props.router.location.search);
    if ("r" in urlParams) {
      let result = await getResponseData(urlParams.r);
      this.id = urlParams.r;
      if (result["status"] === true) {
        var state = this.state;
        state["portrait"] = [];
        Object.keys(result["survey_data"]).forEach(eachKey => {
          state[eachKey] = result["survey_data"][eachKey];
        });
        state["loading"] = false;
        state["readOnly"] = result["catalogue_pay_transaction_ref"] !== "";
        state["loadedItems"] = result["items"];
        if (state["loadedItems"].length === 0) {
          state["loadedItems"] = [];
        }
        if (state["readOnly"]) {
          this.paidItemsID = result["pay_items_id"];
        }
        //console.log("resp: " + result["survey_data"]["response_time"]);
        if (result["survey_data"]["response_time"] > 0) {
          //var t = new Date( result["survey_data"]["response_time"] );
          //console.log("t: " + t);
          var t = result["survey_data"]["response_time"] * 1000;
          var formatted = new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(t);
          state["response_time"] = formatted;
          //console.log("formatted : " + formatted);
        }
        state["catalogue_pay_transaction_ref"] =
          result["senangpay_catalogue_invoice_id"];

        /*
                if(result["catalogue_pay_transaction_time"] > 0){
                    var t = result["catalogue_pay_transaction_time"] * 1000;
                    var formatted = new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(t);
                    state["catalogue_pay_transaction_time"] = formatted;
                }*/
        if (result["catalogue_pay_transaction_time"] !== "") {
          state["catalogue_pay_transaction_time"] =
            result["catalogue_pay_transaction_time"];
        }

        if (urlParams.f === "1") {
          state["portrait"].push(
            "https://pgw.udn.com.tw/gw/photo.php?u=https://uc.udn.com.tw/photo/2021/08/12/realtime/13315182.jpg&x=0&y=0&sw=0&sh=0&sl=W&fw=1050"
          );
          state["portrait"].push(
            "https://st4.depositphotos.com/21607914/24188/i/1600/depositphotos_241889132-stock-photo-japanese-model-singer-sasaki-nozomii.jpg"
          );
        }
        this.setState(state);
      }
    }
  }

  deleteLoadedItem(id) {
    var items = [];
    var refs = [];
    this.state.loadedItems.forEach(eachItem => {
      if (eachItem !== id) {
        items.push(eachItem);
      }
    });

    this.allLoadedItemRefs.forEach(eachRef => {
      if (eachRef !== null) {
        if (eachRef.getID() !== id) {
          refs.push(eachRef);
        }
      }
    });
    this.allLoadedItemRefs = refs;
    this.setState({ loadedItems: items });
  }

  deleteItem(id) {
    var items = [];
    var refs = [];
    this.state.items.forEach(eachItem => {
      if (eachItem !== id) {
        items.push(eachItem);
      }
    });

    this.allItemRefs.forEach(eachRef => {
      if (eachRef !== null) {
        if (eachRef.getID() !== id) {
          refs.push(eachRef);
        }
      }
    });
    this.allItemRefs = refs;
    this.setState({ items: items });
  }

  addItem() {
    var currentItem = [];
    this.state.items.forEach(eachItem => {
      currentItem.push(eachItem);
    });
    currentItem.push(generateID(9));
    this.setState({ items: currentItem });
  }

  async sendData() {
    this.setState({ loading: true, doubleConfirm: false });
    var allItemsInfo = [];
    this.allItemRefs.forEach(eachItem => {
      console.log(eachItem.isFilled(), "filled or not ");
      if (eachItem.isFilled()) {
        allItemsInfo.push(eachItem.getItemData());
      }
    });
    this.allLoadedItemRefs.forEach(eachItem => {
      console.log(eachItem.isFilled(), "filled or notxx ");
      if (eachItem.isFilled()) {
        allItemsInfo.push(eachItem.getItemData());
      }
    });
    var result = await insertCatalogueItems(this.id, allItemsInfo);
    if (result.status === true) {
      this.setState({ loading: false, message: 1 });
    } else {
      this.setState({ loading: false, message: 2 });
    }
  }

  async sendManualTransfer() {
    this.setState({ loading: true, doubleConfirmManualTransfer: false });
    var allItemsInfo = [];
    this.allItemRefs.forEach(eachItem => {
      console.log(eachItem.isFilled(), "filled or not ");
      if (eachItem.isFilled()) {
        allItemsInfo.push(eachItem.getItemData());
      }
    });
    this.allLoadedItemRefs.forEach(eachItem => {
      console.log(eachItem.isFilled(), "filled or notxx ");
      if (eachItem.isFilled()) {
        allItemsInfo.push(eachItem.getItemData());
      }
    });
    var result = await manualPay(this.id);
    if (result.status === true) {
      this.setState({ loading: false, message: 3 });
    } else {
      this.setState({ loading: false, message: 2 });
    }
  }

  isItPaid(tracker) {
    return this.paidItemsID.includes(tracker);
  }

  render() {
    return (
      <div className="parent dis-content">
        <div className="dashboard-content full-width">
          <img className="logo" src={Logo} alt="logo" />
          <div className="hori center-everything">
            Reference ID :<div className="result">{this.state.response_id}</div>
          </div>
          <div className="hori center-everything">
            Survey Transaction Reference :
            <div className="result">
              {this.state.transaction_ref === ""
                ? "-"
                : this.state.transaction_ref}
            </div>
          </div>
          <div className="hori center-everything">
            Survey Response Date :
            <div className="result">
              {this.state.response_time === "" ? "-" : this.state.response_time}
            </div>
          </div>
          <div className="hori center-everything">
            Catalogue Pay Transaction Reference :
            <div className="result">
              {this.state.catalogue_pay_transaction_ref === ""
                ? "-"
                : this.state.catalogue_pay_transaction_ref}
            </div>
          </div>
          <div className="hori center-everything">
            Catalogue Pay Transaction Time :
            <div className="result">
              {this.state.catalogue_pay_transaction_time === ""
                ? "-"
                : this.state.catalogue_pay_transaction_time}
            </div>
          </div>
          <div className="basic-info-grp">
            <div className="title-text">Responder's Personal Information</div>
            <div className="hori center-everything">
              Referral Code :
              <div className="result">{this.state.referral_code}</div>
            </div>
            <div className="hori center-everything">
              Name :<div className="result">{this.state.name}</div>
            </div>
            <div className="hori center-everything">
              IC Number :<div className="result">{this.state.nric}</div>
            </div>
            <div className="hori center-everything">
              Date of Birth :<div className="result">{this.state.dob}</div>
            </div>
            <div className="hori center-everything">
              Phone Number :
              <div
                className="result"
                onClick={() => window.open(`tel:${this.state.phone}`)}>
                {this.state.phone}
              </div>
            </div>
            <div className="hori center-everything">
              E-mail :<div className="result">{this.state.email}</div>
            </div>
            <div className="hori center-everything">
              Address :<div className="result">{this.state.address}</div>
            </div>
            <div className="hori center-everything">
              Instagram ID :<div className="result">{this.state.ig_id}</div>
            </div>
            <div className="hori center-everything">
              Facebook ID :<div className="result">{this.state.fb_id}</div>
            </div>
            <div className="hori center-everything">
              Occupation :<div className="result">{this.state.occupation}</div>
            </div>
          </div>

          <div className="basic-info-grp">
            <div className="title-text">Responder's Body Measurement</div>
            <div className="hori center-everything">
              Height :
              <div className="result">
                {this.state.height}
                <span> </span>cm
              </div>
            </div>
            <div className="hori center-everything">
              Weight :
              <div className="result">
                {this.state.weight}
                <span> </span>kg
              </div>
            </div>
            <div className="hori center-everything">
              Shoulder :
              <div className="result">
                {this.state.shoulder}
                <span> </span>inches
              </div>
            </div>
            <div className="hori center-everything">
              Chest :
              <div className="result">
                {this.state.bust}
                <span> </span>inches
              </div>
            </div>
            <div className="hori center-everything hide">
              Underbust :
              <div className="result">
                {this.state.underbust}
                <span> </span>inches
              </div>
            </div>
            <div className="hori center-everything">
              Waist :
              <div className="result">
                {this.state.waist}
                <span> </span>inches
              </div>
            </div>
            <div className="hori center-everything">
              Hips :
              <div className="result">
                {this.state.hips}
                <span> </span>inches
              </div>
            </div>
            <div className="hori center-everything hide">
              Thigh :
              <div className="result">
                {this.state.thigh}
                <span> </span>inches
              </div>
            </div>
            <div className="hori center-everything">
              Top Size :<div className="result">{this.state.size_top}</div>
            </div>
            <div className="hori center-everything">
              Bottom Size :
              <div className="result">{this.state.size_bottom}</div>
            </div>
          </div>
          <div className="basic-info-grp">
            <div className="title-text">Responder's Style Preference</div>
            <div className="hori center-everything">
              Color That She Prefers :
              <div className="result">{this.state.never_wear_color}</div>
            </div>
            <div className="hori center-everything">
              Designs (Printed, Plain) :
              <div className="result">{this.state.design}</div>
            </div>
            <div className="hori center-everything">
              Product Preference :
              <div className="result">{this.state.wish_to_receive}</div>
            </div>
            <div className="hori center-everything">
              Collection Preference :
              <div className="result">{this.state.current_outfit}</div>
            </div>
            <div className="hori center-everything">
              Pattern & Fabric Preference :
              <div className="result">{this.state.pattern}</div>
            </div>
            <div className="hori center-everything">
              Top Preference :
              <div className="result">{this.state.top_preference}</div>
            </div>
            <div className="hori center-everything">
              Sleeve Preference :
              <div className="result">{this.state.sleeve_preference}</div>
            </div>
            <div className="hori center-everything">
              Skirt Preference :
              <div className="result">{this.state.skirt_preference}</div>
            </div>
            <div className="hori center-everything">
              Dress Preference :
              <div className="result">{this.state.dress_preference}</div>
            </div>
            <div className="hori center-everything">
              Pants Preference :
              <div className="result">{this.state.pants_preference}</div>
            </div>
            <div className="hori center-everything">
              Jeans Preference :
              <div className="result">{this.state.jeans_preference}</div>
            </div>
          </div>
          <div className="basic-info-grp hide">
            <div className="title-text">Responder's Body Image</div>
            <div className="hori center-everything">
              Body Type :<div className="result">{this.state.body_type}</div>
            </div>
            <div className="hori center-everything">
              Skin Tone :<div className="result">{this.state.skin_tone}</div>
            </div>
          </div>
          <div className="basic-info-grp">
            <div className="title-text">Print Quotation</div>
            {this.state.loadedItems ? (
              <a
                href={
                  "https://try.whattowear.com.my/survey/print/generate.php?k=a938y8@3s&rid=" +
                  this.state.response_id
                }
                rel="noopener noreferrer"
                target="_blank">
                Get PDF
              </a>
            ) : (
              <div className="result">No items yet</div>
            )}
          </div>
          <div className="basic-info-grp">
            <div className="title-text">Portraits</div>
            {this.state.portrait.length === 0 ? (
              <div>Portraits unavailable</div>
            ) : (
              <div className="reply-portrait-parent">
                {this.state.portrait.map(eachPortrait => {
                  return (
                    <img className="reply-portrait" src={eachPortrait} alt="" />
                  );
                })}
              </div>
            )}
          </div>
          <div className="basic-info-grp">
            <div className="title-text">Items</div>
            {this.state.catalogue_pay_transaction_ref.includes("manual_") ? (
              <div style={{ fontSize: "2em", color: "green" }}>
                This order is manually marked done.
              </div>
            ) : (
              ""
            )}
            {this.state.readOnly
              ? null
              : this.state.loadedItems.map((eachItem, i) => {
                  if (i === 0) {
                    this.allLoadedItemRefs = [];
                  }
                  var newRef = React.createRef();
                  this.allLoadedItemRefs.push(newRef);
                  return (
                    <NewItem
                      readOnly={this.state.readOnly}
                      ref={e => (this.allLoadedItemRefs[i] = e)}
                      onDelete={this.deleteLoadedItem}
                      key={"loaded-newItem-" + eachItem.id}
                      presets={eachItem}
                      id={eachItem.id}
                    />
                  );
                })}
            {this.state.readOnly
              ? this.state.loadedItems.map((eachItem, i) => {
                  return (
                    <CartItem
                      containMode={true}
                      key={"cart-item-" + i}
                      cartInfo={eachItem}
                      forceCheck={this.isItPaid(eachItem.id)}
                    />
                  );
                })
              : this.state.items.map((eachItem, i) => {
                  if (i === 0) {
                    this.allItemRefs = [];
                  }
                  var newRef = React.createRef();
                  this.allItemRefs.push(newRef);
                  return (
                    <NewItem
                      readOnly={this.state.readOnly}
                      ref={e => (this.allItemRefs[i] = e)}
                      onDelete={this.deleteItem}
                      key={"newItem-" + eachItem}
                      id={eachItem}
                    />
                  );
                })}

            {this.state.readOnly ? null : (
              <div className="add-item-btn" onClick={this.addItem}>
                Add Item
              </div>
            )}
          </div>
          {this.state.readOnly ? null : (
            <div
              className="send-btn"
              onClick={() => {
                this.setState({ doubleConfirm: true });
              }}>
              Send
            </div>
          )}

          {this.state.readOnly ? null : (
            <div
              className="send-btn"
              onClick={() => {
                this.setState({ doubleConfirmManualTransfer: true });
              }}>
              Direct Transfer Done
            </div>
          )}
        </div>
        {this.state.loading ? (
          <div className="loading-bg">
            <TailSpin color="#fdf9f0" height={80} width={80} />
          </div>
        ) : null}
        {this.state.doubleConfirm ? (
          <div className="loading-bg">
            <div className="title-text alert-text">
              Are you sure you want to submit this catalogue?
            </div>
            <div className="send-now-btn white-border" onClick={this.sendData}>
              Send Now
            </div>
            <div
              className="dont-send-now-btn"
              onClick={() => {
                this.setState({ doubleConfirm: false });
              }}>
              No
            </div>
          </div>
        ) : null}
        {this.state.doubleConfirmManualTransfer ? (
          <div className="loading-bg">
            <div className="title-text alert-text">
              Are you sure manual transaction is done for this catalogue?
            </div>
            <div
              className="send-now-btn white-border"
              onClick={this.sendManualTransfer}>
              Yes
            </div>
            <div
              className="dont-send-now-btn"
              onClick={() => {
                this.setState({ doubleConfirmManualTransfer: false });
              }}>
              NoNoNo
            </div>
          </div>
        ) : null}
        {this.state.message === 1 ? (
          <div className="loading-bg">
            <div className="title-text alert-text">
              Catalogue has been submitted successfully.
            </div>
            <div
              className="send-now-btn white-border"
              onClick={() => {
                this.props.router.navigate("/dashboard");
              }}>
              OK
            </div>
          </div>
        ) : null}
        {this.state.message === 2 ? (
          <div className="loading-bg">
            <div className="title-text alert-text">
              Failed to submit catalogue at the moment, please try again later.
            </div>
            <div
              className="send-now-btn white-border"
              onClick={() => {
                this.setState({ message: 0 });
              }}>
              OK
            </div>
          </div>
        ) : null}
        {this.state.message === 3 ? (
          <div className="loading-bg">
            <div className="title-text alert-text">
              Marked as manual transfer.
            </div>
            <div
              className="send-now-btn white-border"
              onClick={() => {
                this.props.router.navigate("/dashboard");
              }}>
              OK
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(ReplyResponsePage);
