import "./ExpandableFaq.css";
import Collapse from "../Images/collapse.svg";
import Expand from "../Images/expand.svg";
import React from "react";
import PropTypes from 'prop-types';

class ExpandableFaq extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            expand: false
        }
    }

    render()
    {
        return (
            <div className="full-width pad-h">
                <div
                    className="faq-question"
                    onClick={() => {
                        console.log(">>")
                    this.setState({
                        expand: !this.state.expand
                    });
                }}>
                    <div className="faq-question-text">{this.props.question}</div>
                    <img
                        className="img-button"
                        src={this.state.expand
                        ? Collapse
                        : Expand}
                        alt="expand collapse button"/>

                </div>
                <div className="divider"></div>
                {this
                    .props
                    .answer
                    .split("\n")
                    .map((a, i) => {
                        // console.log(a);
                        return (<div
                            key={`${this.props.question}-${i}`}
                            dangerouslySetInnerHTML={{
                            __html: a
                        }}
                            style={{
                            "maxHeight": this.state.expand
                                ? 500
                                : 0,
                            opacity: this.state.expand
                                ? 1
                                : 0
                        }}
                            className="faq-answer"/>)
                    })}
            </div>
        );
    }
}

ExpandableFaq.propTypes = {
    question: PropTypes.string,
    answer: PropTypes.string
};

ExpandableFaq.defaultProps = {
    question: "Question",
    answer: "Answer"
};

export default ExpandableFaq;