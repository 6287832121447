import Compress from "compress.js";
export function getProperDate(d) {
  var date = new Date(d * 1000);
  var iso = date.toISOString().match(/(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})/);
  return iso[1] + " " + iso[2];
}

export async function resizeImage(file) {
  if (file.type !== "image/png") {
    const compress = new Compress();
    const resizedImage = await compress.compress([file], {
      size: 2,
      quality: 1,
      maxWidth: 1024,
      maxHeight: 1024,
      resize: true,
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt);
    return resizedFiile;
  }
  return file;
}

export function getBase64(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {};
}

export function debugPrint(e) {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    console.log(e);
  }
}
