import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { debugPrint } from "../Utilities/Utilities";
import UploadImageIcon from "../Images/ImageUpload.svg";

class DragAndDropImageUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultImage: "",
      imageB64: "",
      prepareToUploadImage: true,
      showAlertDialog: false,
      currentWidth: 0,
      currentHeight: 0,
      mobileMode: false,
      alertDialogTitle: "Message Dialog Title",
      alertDialogMessage: "Message Dialog Message",
    };
  }

  enableAlertDialog(title, message) {
    this.setState({
      alertDialogTitle: title,
      alertDialogMessage: message,
      showAlertDialog: true,
    });
  }

  disableAlertDialog() {
    this.setState({ showAlertDialog: false });
  }

  componentDidMount() {
    var temp = "";
    if (this.props.defaultImage !== undefined) {
      temp = this.props.defaultImage;
    }
    this.setState({
      imageB64: temp,
      prepareToUploadImage: this.props.prepareToUploadImage,
    });
    debugPrint("passed in defaultimage is ", temp);
  }

  addImage(inputFile) {
    this.getBase64FromImage(inputFile[0]);
    debugPrint(inputFile[0]);
  }

  saveImage(imageString) {
    this.setState({ imageB64: imageString });
    this.props.parentCallback(imageString);
  }

  getBase64FromImage(file) {
    let this2 = this;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      this2.saveImage(reader.result);
    };
    reader.onerror = function (error) {
      debugPrint("Error: ", error);
    };
  }

  render() {
    return (
      <div
        className="dragAndDrop-container"
        style={{
          borderRadius: 16,
          backgroundColor: "#FFFEF8",
          height: "125px",
          border: " 2px dashed #B1B0A8",
          justifyContent: "center",
          alignContent: "center",
          position: "relative",
        }}>
        <Dropzone
          style={{
            width: "100%",
            height: "100%",
          }}
          onDrop={acceptedFiles => this.addImage(acceptedFiles)}
          className="noselect">
          {({ getRootProps, getInputProps }) => (
            <section
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}>
              <div
                {...getRootProps()}
                style={{
                  display: "flex",
                  justifySelf: "center",
                  alignSelf: "center",
                  height: "100%",
                }}>
                <input {...getInputProps()} />
                {
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}>
                    <img src={UploadImageIcon} alt="" />
                    <div
                      style={{
                        fontFamily: "Raleway Semibold",
                        textAlign: "center",
                        padding: "0.5em",
                      }}>
                      Choose photo
                    </div>
                  </div>
                }
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  }
}

DragAndDropImageUploader.propTypes = {
  parentCallback: PropTypes.func,
};

DragAndDropImageUploader.defaultProps = {
  parentCallback: function (s) {
    debugPrint(`DragAndDropImageUploader`);
  },
};

export default DragAndDropImageUploader;
