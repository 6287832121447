import React from "react";
import PropTypes from "prop-types";
import { debugPrint } from "../Utilities/Utilities";

class ItemPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deselected: false,
    };
  }

  componentDidMount() {
    // console.log(this.props.cartInfo.price)
  }

  render() {
    return (
      <div className="div_quiz_selection">
        <div
          onClick={() => {
            let newState = !this.state.deselected;
            this.setState({ deselected: newState });
            this.props.onClick(newState, this.props.label);
          }}
          className="quiz_selection"
          style={{
            backgroundImage: "url(" + this.props.img + ")",

            height:
              this.props.circleOrSquare === "circle"
                ? this.props.size + "px"
                : this.props.size / 0.8 + "px",
            width: this.props.size + "px",
            border:
              this.props.circleOrSquare === "circle" ? "1px solid black" : "",
            borderRadius: this.props.circleOrSquare === "circle" ? "50%" : "",
            textDecoration: this.state.deselected ? "unset" : "line-through",
            filter: `brightness(${
              this.state.deselected ? "1" : "0.5"
            }) saturation(${this.state.deselected ? "1" : "0.75"})`,
            opacity: this.state.deselected ? "1" : "0.45",
          }}></div>
        <div className="div_quiz_text">{this.props.label}</div>
      </div>
    );
  }
}

ItemPicker.propTypes = {
  img: PropTypes.string,
  textColor: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  circleOrSquare: PropTypes.string,
};

ItemPicker.defaultProps = {
  img: "",
  textColor: "#333",
  label: "Geometry",
  size: "72px",
  circleOrSquare: "circle",
  onClick: (newState, color) => {
    debugPrint("Clicked color picker -> " + newState + color);
  },
};

export default ItemPicker;
