import "./Footer.css";
import React from "react";
import PropTypes from "prop-types";
import FooterText from "../Images/wtw_logo.png";
import FBIcon from "../Images/fb_icon.svg";
import IGIcon from "../Images/ig_icon.svg";
import TGIcon from "../Images/telegram.svg";
import WAIcon from "../Images/whatsapp.svg";

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expand: false,
      mobileMode: false,
    };

    this._isMounted = false;
  }

  updateState(state) {
    if (this._isMounted) {
      this.setState(state);
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  handleResize = e => {
    this.updateState({
      mobileMode: window.innerWidth < 650,
      currentWidth: window.innerWidth,
      currentHeight: window.innerHeight,
    });
  };

  render() {
    return (
      <div className="isolate">
        <a target="_blank" rel="noreferrer" href="https://whattowear.com.my">
          <img className="footer-img" src={FooterText} alt="footer" />
        </a>
        <div style={{ margin: "2.5em auto 1em auto" }}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/whattowearmalaysia/">
            <img className="inline-icon" src={FBIcon} alt="fb" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://t.me/whattowearlifestylechannel">
            <img className="inline-icon" src={TGIcon} alt="ig" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/whattowear.ai">
            <img className="inline-icon" src={IGIcon} alt="ig" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send/?phone=60136838383">
            <img className="inline-icon" src={WAIcon} alt="ig" />
          </a>
        </div>
        <div
          className={`footer-parent ${
            this.state.mobileMode ? "footer-vertical" : ""
          }`}>
          <a
            href="https://whattowear.com.my/pages/privacy-policy"
            className="footer-text">
            Privacy Policy
          </a>
          <a
            href="https://whattowear.com.my/pages/terms-and-conditions"
            className="footer-text">
            Terms & Conditions
          </a>
          <a
            href="https://whattowear.com.my/pages/terms-and-conditions"
            className="footer-text">
            Cancellation & Refund Policy
          </a>
          <a
            href="https://whattowear.com.my/pages/faqs"
            className="footer-text">
            FAQ
          </a>
          <a
            href="https://whattowear.com.my/pages/stores"
            className="footer-text">
            Stores & Contact
          </a>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
};

Footer.defaultProps = {
  question: "Question",
  answer: "Answer",
};

export default Footer;
