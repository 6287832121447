

import React from "react";
import PropTypes from 'prop-types';
import { debugPrint } from "../Utilities/Utilities";

class ColorPicker extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            deselected: false
        }
    }

    componentDidMount()
    {
        // console.log(this.props.cartInfo.price)
    }

    render()
    {
        return (
            <div
                onClick={()=> {
                    let newState = !this.state.deselected;
                    this.setState({deselected: newState});
                    this.props.onClick(newState, this.props.label);
                }}
                className="color-picker-options"
                style={{
                background: this.props.color,
                color: this.props.textColor,
                height: this.props.size,
                lineHeight: this.props.size,
                width: this.props.size,
                borderRadius: this.props.size,
                textDecoration: this.state.deselected ? "unset" : "line-through",
                filter: `brightness(${this.state.deselected ? "1" : "0.5"}) saturation(${this.state.deselected ? "1" : "0.75"})`,
                opacity: this.state.deselected ? "1" : "0.45"
            }}>
                {this.props.label}
            </div>
        );
    }
}

ColorPicker.propTypes = {
    color: PropTypes.string,
    textColor: PropTypes.string,
    label: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func
};

ColorPicker.defaultProps = {
    color: "#000000",
    textColor: "white",
    label: "Black",
    size: "72px",
    onClick: (newState, color)=>{
        debugPrint("Clicked color picker -> " + newState + color)
    }

};

export default ColorPicker;