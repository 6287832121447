import React from "react";
import queryString from "query-string";
import "./CheckOutPage.css";
import "./common.css";
import Logo from "../Images/wtw_logo.png";
import NameIcon from "../Images/name_icon.svg";
import PhoneIcon from "../Images/phone_icon.svg";
import ICIcon from "../Images/ic_icon.svg";
import EmailIcon from "../Images/email_icon.svg";
import AddressIcon from "../Images/address_icon.svg";
import CartItem from "../Components/CartItem";
import Footer from "../Components/Footer";
import {
  getCatalogueItems,
  cataloguePay,
  isNumeric,
} from "../Utilities/Requests";
import { TailSpin } from "react-loader-spinner";
import withRouter from "../Utilities/withRouter";

class CheckOutPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileMode: false,
      cartItems: [],
      name: "",
      email: "",
      phone: "",
      ic: "",
      address: "",
      showError: false,
      showErrorDialog: false,
      loading: true,
    };

    this._isMounted = false;
    this.googleForm = React.createRef();

    this.setCartChecked = this.setCartChecked.bind(this);

    this.totalSelected = this.totalSelected.bind(this);

    this.totalPrice = this.totalPrice.bind(this);

    this.checkOut = this.checkOut.bind(this);

    this.checkSubmit = this.checkSubmit.bind(this);

    this.id = "";
  }

  resizeIframe() {
    // if (this.googleForm.current != null) {     this.googleForm.current.height =
    // '0px';
    // console.log(this.googleForm.current.contentWindow.document.documentElement.sc
    // r ollHeight) } this.googleForm.current.style.height =
    // this.googleForm.current.contentWindow.document.documentElement.scrollHeight +
    // 'px';
  }
  checkString(s) {
    return s.replace(" ", "") !== "";
  }

  checkSubmit() {
    let toCheck = [
      this.state.name,
      this.state.ic,
      this.state.phone,
      this.state.email,
      this.state.address,
    ];
    var pass = true;
    toCheck.forEach(eachToCheck => {
      if (!this.checkString(eachToCheck)) {
        console.log(eachToCheck);
        pass = false;
        return false;
      }
    });

    if (!pass) {
      this.setState({ showError: true });
    }
    return pass;
  }

  async checkOut() {
    this.setState({ loading: true });
    if (this.checkSubmit()) {
      var buyItems = [];
      this.state.cartItems.forEach(eachItem => {
        if (eachItem.checked) {
          buyItems.push(eachItem.id);
        }
      });
      var result = await cataloguePay(
        this.id,
        buyItems,
        this.state.name,
        this.state.phone,
        this.state.ic,
        this.state.email,
        this.state.address
      );
      if (result.status === true) {
        window.sendTracking("navigate", "payment", "2");
        window.location.href =
          "https://try.whattowear.com.my/survey/pay/pay_catalogue_stripe.php?r=" +
          this.id;
      } else {
        this.setState({ loading: false, showErrorDialog: true });
      }
    }
    this.setState({ loading: false });
  }

  updateState(state) {
    if (this._isMounted) {
      this.setState(state);
    }
  }

  handleResize = e => {
    this.updateState({
      mobileMode: window.innerWidth < 650,
      currentWidth: window.innerWidth,
      currentHeight: window.innerHeight,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    window.sendTracking("enter", "page", "checkout");
    this.getCatalogueInfo();
  }

  async getCatalogueInfo() {
    this.setState({ loading: true });
    let urlParams = queryString.parse(this.props.router.location.search);
    if ("c" in urlParams) {
      let result = await getCatalogueItems(urlParams.c);
      this.id = urlParams.c;
      if (result["status"] === true) {
        var newItems = [];
        result.items.forEach(eachItem => {
          eachItem["checked"] = false;
          newItems.push(eachItem);
        });
        this.setState({ loading: false, cartItems: newItems });
      } else {
      }
    }
  }
  setCartChecked(id) {
    var updateCartItems = this.state.cartItems;
    updateCartItems.forEach(eachCartItem => {
      if (eachCartItem.id === id) {
        eachCartItem.checked = !eachCartItem.checked;
      }
    });

    this.setState({ cartItems: updateCartItems });
  }

  totalSelected() {
    var totalSelectedCount = 0;
    this.state.cartItems.forEach(eachCartItem => {
      if (eachCartItem.checked) {
        totalSelectedCount += 1;
      }
    });
    return totalSelectedCount;
  }

  totalPrice() {
    var totalPriceCount = 0;
    this.state.cartItems.forEach(eachCartItem => {
      if (eachCartItem.checked) {
        if (isNumeric(eachCartItem.price)) {
          totalPriceCount += Number(eachCartItem.price);
        }
      }
    });
    return (Math.round(totalPriceCount * 100) / 100).toFixed(2);
  }

  render() {
    return (
      <div className="parent">
        <div id="checkoutpage" className="content">
          <img className="logo" src={Logo} alt="logo" />
          <div className="clamp-width">
            {this.state.cartItems.map((eachCartItem, i) => {
              return (
                <CartItem
                  key={"cartItem" + i}
                  cartInfo={eachCartItem}
                  mobileMode={this.state.mobileMode}
                  onSelectionChanged={this.setCartChecked}
                />
              );
            })}
          </div>

          <div className="selected-items-frame clamp-width">
            <div
              className={
                this.state.mobileMode
                  ? "text-selected-items-mobile"
                  : "text-selected-items"
              }>
              <div>
                Selected {this.totalSelected()}
                Items
              </div>
              <div>
                <div>Total:</div>
                <div className="text-name">RM{this.totalPrice()}</div>
              </div>
            </div>
          </div>

          {this.state.mobileMode ? (
            <div className="isolate">
              <div className="input-parent">
                <div className="input-title">
                  <img className="input-icon" src={NameIcon} alt="icon" />
                  <input
                    type="text"
                    placeholder="Your Name *"
                    value={this.state.name}
                    onInput={e =>
                      this.setState({ name: e.currentTarget.value })
                    }
                  />
                </div>
              </div>
              <div className="input-parent">
                <div className="input-title">
                  <img className="input-icon" src={ICIcon} alt="icon" />
                  <input
                    type="text"
                    placeholder="IC Number *"
                    value={this.state.ic}
                    onInput={e => this.setState({ ic: e.currentTarget.value })}
                  />
                </div>
              </div>
              <div className="input-parent">
                <div className="input-title">
                  <img className="input-icon" src={PhoneIcon} alt="icon" />
                  <input
                    type="text"
                    placeholder="Phone *"
                    value={this.state.phone}
                    onInput={e =>
                      this.setState({ phone: e.currentTarget.value })
                    }
                  />
                </div>
              </div>
              <div className="input-parent">
                <div className="input-title">
                  <img className="input-icon" src={EmailIcon} alt="icon" />
                  <input
                    type="text"
                    placeholder="E-mail *"
                    value={this.state.email}
                    onInput={e =>
                      this.setState({ email: e.currentTarget.value })
                    }
                  />
                </div>
              </div>
              <div className="input-parent">
                <div className="input-title">
                  <img className="input-icon" src={AddressIcon} alt="icon" />
                  <input
                    type="text"
                    placeholder="Address *"
                    value={this.state.address}
                    onInput={e =>
                      this.setState({ address: e.currentTarget.value })
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="isolate clamp-width">
              <div className="grid-2">
                <div className="input-parent">
                  <div className="input-title">
                    <img className="input-icon" src={NameIcon} alt="icon" />
                    <input
                      type="text"
                      placeholder="Your Name *"
                      value={this.state.name}
                      onInput={e =>
                        this.setState({ name: e.currentTarget.value })
                      }
                    />
                  </div>
                </div>
                <div className="input-parent">
                  <div className="input-title">
                    <img className="input-icon" src={ICIcon} alt="icon" />
                    <input
                      type="text"
                      placeholder="IC Number *"
                      value={this.state.ic}
                      onInput={e =>
                        this.setState({ ic: e.currentTarget.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="grid-2">
                <div className="input-parent">
                  <div className="input-title">
                    <img className="input-icon" src={PhoneIcon} alt="icon" />
                    <input
                      type="text"
                      placeholder="Phone *"
                      value={this.state.phone}
                      onInput={e =>
                        this.setState({ phone: e.currentTarget.value })
                      }
                    />
                  </div>
                </div>
                <div className="input-parent">
                  <div className="input-title">
                    <img className="input-icon" src={EmailIcon} alt="icon" />
                    <input
                      type="text"
                      placeholder="E-mail *"
                      value={this.state.email}
                      onInput={e =>
                        this.setState({ email: e.currentTarget.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="input-parent">
                <div className="input-title">
                  <img className="input-icon" src={AddressIcon} alt="icon" />
                  <input
                    type="text"
                    placeholder="Address *"
                    value={this.state.address}
                    onInput={e =>
                      this.setState({ address: e.currentTarget.value })
                    }
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.showError ? (
            <div className="error margin-bot-s">
              Please make sure you have filled up all the required information
              before checkout.
            </div>
          ) : null}
          <div className="black-button" onClick={this.checkOut}>
            Checkout
          </div>
          <Footer />
        </div>
        {this.state.loading ? (
          <div className="loading-bg">
            <TailSpin color="#fdf9f0" height={80} width={80} />
          </div>
        ) : null}
        {this.state.showErrorDialog === true ? (
          <div className="loading-bg">
            <div className="title-text alert-text">
              Unable to checkout at the moment, please try again later.
            </div>
            <div
              className="send-now-btn white-border"
              onClick={() => {
                this.setState({ showErrorDialog: false });
              }}>
              OK
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(CheckOutPage);
