import React from "react";
import PropTypes from 'prop-types';
import "./PageNavigation.css";
import NextIcon from "../Images/nextButton.svg";
import NextIconHover from "../Images/nextButtonHover.svg";
import PrevIcon from "../Images/prevButton.svg";
import PrevIconHover from "../Images/prevButtonHover.svg";
import FirstPageIcon from "../Images/firstPageButton.svg";
import FirstPageIconHover from "../Images/firstPageButtonHover.svg";
import LastPageIcon from "../Images/lastPageButton.svg";
import LastPageIconHover from "../Images/lastPageButtonHover.svg";

class PageNavigation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundColor: "",
            fontColor: ""
        };

        this.firstPage = this
            .firstPage
            .bind(this);
        this.prevPage = this
            .prevPage
            .bind(this);
        this.nextPage = this
            .nextPage
            .bind(this);
        this.lastPage = this
            .lastPage
            .bind(this);

        this.first = React.createRef();
        this.prev = React.createRef();
        this.next = React.createRef();
        this.last = React.createRef();

        this.range = 1;
    }

    getShowingPages() {
        var totalPages = [];
        var lowest = this.props.currentPage - this.range;
        var highest = this.props.currentPage + this.range;

        for (var j = 1; j <= this.range; j++) {
            totalPages.push(this.props.currentPage + j);
            totalPages.push(this.props.currentPage - j);
        }

        totalPages.push(this.props.currentPage);
        var updatedTotalPages = [];
        totalPages.forEach(eachPage => {
            if (eachPage <= 0) {
                updatedTotalPages.push(highest + Math.abs(eachPage - 1));
            } else if (eachPage > this.props.totalPage) {
                updatedTotalPages.push(lowest - Math.abs(eachPage - this.props.totalPage));
            } else {
                updatedTotalPages.push(eachPage);
            }
        });

        var finalFilter = []
        updatedTotalPages.forEach(eachPage => {
            if(eachPage > 0 && eachPage <= this.props.totalPage)
            {
                finalFilter.push(eachPage);
            }
        });
        
        return finalFilter.sort(function (a, b) {
            return a - b;
        });
    }

    componentDidMount() {
        console.log(this.props.enableFirstLastButton)
    }

    firstPage() {
        this.jumpToPage(1)
    }

    nextPage() {
        this.jumpToPage(Math.min(this.props.currentPage + 1, this.props.totalPage));
    }

    prevPage() {
        this.jumpToPage(Math.max(this.props.currentPage - 1, 1));
    }

    lastPage() {
        this.jumpToPage(this.props.totalPage);
    }

    jumpToPage(page) {
        this.props.onPageChange(page);
    }

    render() {
        return (
            <div className="page-navigation-parent">
                {this.props.enableFirstLastButton
                    ? <div
                        className={`btn-frame first-btn ${this.props.currentPage === 1
                            ? "end-of-page"
                            : ""}`}
                        onClick={this.firstPage}
                        onMouseOver={e => {
                            this.first.src = FirstPageIconHover;
                        }}
                        onMouseOut={e => {
                            this.first.src = FirstPageIcon;
                        }}>
                        <img
                            ref={e => this.first = e}
                            style={{
                                opacity: this.props.currentPage === 1
                                    ? 0.25
                                    : 1
                            }}
                            src={FirstPageIcon} alt="first"/>
                    </div>
                    : null}
                <div
                    className={`btn-frame ${this.props.currentPage === 1
                        ? "end-of-page"
                        : ""} ${this.props.enableFirstLastButton
                            ? ""
                            : "first-btn"}`}
                    onClick={this.prevPage}
                    onMouseOver={e => {
                        this.prev.src = PrevIconHover;
                    }}
                    onMouseOut={e => {
                        this.prev.src = PrevIcon;
                    }}>
                    <img
                        ref={e => this.prev = e}
                        style={{
                            opacity: this.props.currentPage === 1
                                ? 0.25
                                : 1
                        }}
                        src={PrevIcon} alt="prev" />
                </div>
                {this
                    .getShowingPages()
                    .map((eachPage, i) => (
                        <div
                            key={"page-" + i}
                            className={`btn-frame btn-text ${this.props.currentPage === eachPage
                                ? "current-page"
                                : ""}`}
                            onClick={() => this.jumpToPage(eachPage)}>
                            {eachPage}
                        </div>
                    ))}
                <div
                    className={`btn-frame ${this.props.currentPage === this.props.totalPage
                        ? "end-of-page"
                        : ""} ${this.props.enableFirstLastButton
                            ? ""
                            : "last-btn"}`}
                    onClick={this.nextPage}
                    onMouseOver={e => {
                        this.next.src = NextIconHover;
                    }}
                    onMouseOut={e => {
                        this.next.src = NextIcon;
                    }}>
                    <img
                        ref={e => this.next = e}
                        style={{
                            opacity: this.props.currentPage === this.props.totalPage
                                ? 0.25
                                : 1
                        }}
                        src={NextIcon} alt="nexr"/>
                </div>
                {this.props.enableFirstLastButton
                    ? <div
                        className={`btn-frame last-btn ${this.props.currentPage === this.props.totalPage
                            ? "end-of-page"
                            : ""}`}
                        onClick={this.lastPage}
                        onMouseOver={e => {
                            this.last.src = LastPageIconHover;
                        }}
                        onMouseOut={e => {
                            this.last.src = LastPageIcon;
                        }}>
                        <img
                            ref={e => this.last = e}
                            style={{
                                opacity: this.props.currentPage === this.props.totalPage
                                    ? 0.25
                                    : 1
                            }}
                            src={LastPageIcon} alt="last"/>
                    </div>
                    : null}
            </div>
        )
    }
}

PageNavigation.propTypes = {
    onPageChange: PropTypes.func,
    enableFirstLastButton: PropTypes.bool,
    totalPage: PropTypes.number,
    currentPage: PropTypes.number
}

PageNavigation.defaultProps = {
    onPageChange: function (currentPage) {
        console.log("Current page is -> ", currentPage);
    },
    enableFirstLastButton: true,
    totalPage: 1,
    currentPage: 1
};

export default PageNavigation;