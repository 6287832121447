import React from "react";
import "./DashboardPage.css";
// import "./common.css";
import Logo from "../Images/wtw_logo.png";
import NextIcon from "../Images/next_icon.svg";
import { getResponseList } from "../Utilities/Requests";
import { getProperDate } from "../Utilities/Utilities";
import PageNavigation from "../Components/PageNavigation";
import { Button, TextField } from "@mui/material";
import withRouter from "../Utilities/withRouter";

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 0,
      responseList: [],
      total: 1,
      currentPage: 1,
      search: "",
    };

    this.itemPerPage = 10;

    this.refreshResponseList = this.refreshResponseList.bind(this);
    this.changeMarkDoneMode = this.changeMarkDoneMode.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.autoSearch = this.autoSearch.bind(this);

    this.timer = null;
  }

  componentDidMount() {
    let jwt = localStorage.getItem("j");
    if (jwt === undefined || jwt === null || jwt === "") {
      setTimeout(() => {
        this.props.router.navigate("/adminlogin");
      }, 500);
    }
    this.refreshResponseList();
  }

  async refreshResponseList() {
    var markDone = 3;
    var transactionType = 3;
    if (this.state.mode === 1) {
      markDone = 2;
    }
    if (this.state.mode === 2) {
      transactionType = 4;
      markDone = 2;
    }

    let result = await getResponseList(
      this.state.currentPage - 1,
      this.itemPerPage,
      transactionType,
      markDone,
      this.state.search
    );

    console.log("total : " + result["total"]);
    if (
      result["response_list"] !== undefined &&
      result["total"] !== undefined
    ) {
      this.setState({
        responseList: result["response_list"],
        total: result["total"],
      });
    }
  }

  async onPageChange(e) {
    this.setState(
      {
        currentPage: e,
      },
      () => {
        this.refreshResponseList();
      }
    );
  }

  changeMarkDoneMode(mode) {
    this.setState(
      {
        mode: mode,
        currentPage: 1,
      },
      () => {
        this.refreshResponseList();
      }
    );
  }

  autoSearch(e) {
    this.countDown = 10;

    if (this.timer === null) {
      this.timer = setInterval(() => {
        this.countDown -= 1;
        if (this.countDown <= 0) {
          this.refreshResponseList(null, null, this.state.search, true);
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 100);
    }
  }

  render() {
    return (
      <div className="parent dis-content">
        <div className="dashboard-content full-width">
          <img className="logo" src={Logo} alt="logo" />
          <div className="option-parent">
            <div
              className={
                this.state.mode === 0
                  ? "option-btn-selected"
                  : "option-btn-not-selected"
              }
              onClick={() => this.changeMarkDoneMode(0)}>
              New response
            </div>
            <div
              className={
                this.state.mode === 1
                  ? "option-btn-selected"
                  : "option-btn-not-selected"
              }
              onClick={() => this.changeMarkDoneMode(1)}>
              Processed
            </div>
            <div
              className={
                this.state.mode === 2
                  ? "option-btn-selected"
                  : "option-btn-not-selected"
              }
              onClick={() => this.changeMarkDoneMode(2)}>
              Paid
            </div>
          </div>
          <TextField
            className="search-bar"
            label="Search"
            variant="outlined"
            value={this.state.search}
            onKeyDown={e => this.autoSearch(e.target.value)}
            onChange={e => {
              this.setState({ search: e.target.value });
            }}
          />{" "}
          {this.state.responseList.map((eachResponse, i) => (
            <div className="full-width" key={"response" + i}>
              <div className="hori response-parent">
                <div className="response-info">
                  <div>Name: {eachResponse.name}</div>
                  <div>Time: {getProperDate(eachResponse.time)}</div>
                  <div className="hori center-everything">
                    Status:
                    <div
                      className={`${
                        eachResponse.transaction_ref === "" ? "alert" : "ok"
                      }`}>
                      {eachResponse.transaction_ref === "" ? "Unpaid" : "Paid"}
                    </div>
                  </div>

                  <div className="divider" />
                  <div className="small-text">
                    Reference ID: {eachResponse.rid}
                  </div>
                  <div className="small-text">
                    Survey Transaction Ref:{" "}
                    {eachResponse.transaction_ref === ""
                      ? "-"
                      : eachResponse.transaction_ref}
                  </div>
                  <div className="small-text">
                    Catalogue Pay Transaction Ref:{" "}
                    {eachResponse.catalogue_pay_transaction_ref === ""
                      ? "-"
                      : eachResponse.catalogue_pay_transaction_ref}
                  </div>
                </div>
                <Button
                  onClick={() => {
                    this.props.router.navigate(
                      "/replyresponse?r=" + eachResponse.rid
                    );
                  }}>
                  <img className="enter-btn" src={NextIcon} alt="next" />
                </Button>
              </div>
              {this.state.mode !== 2 ? (
                <div className="hori center-everything link">
                  Link:
                  <a
                    style={{
                      marginLeft: "1em",
                    }}
                    target={"_blank"}
                    rel="noreferrer"
                    href={`https://try.whattowear.com.my/checkout?c=${eachResponse.rid}`}>
                    https://try.whattowear.com.my/checkout?c={eachResponse.rid}
                  </a>
                </div>
              ) : null}
            </div>
          ))}
          {this.state.total > this.itemPerPage ? (
            <div className="isolate">
              <PageNavigation
                onPageChange={this.onPageChange}
                totalPage={Math.ceil(this.state.total / this.itemPerPage)}
                currentPage={this.state.currentPage}
              />
            </div>
          ) : null}
          {this.state.total === 0 ? (
            <div className="isolate no-item">
              No response has been found in this category
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(DashboardPage);
