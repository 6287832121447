import "./NewItem.css";
import React from "react";
import DeleteIcon from "../Images/delete_icon.svg";
import PropTypes from 'prop-types';
import {InputAdornment, TextField} from "@mui/material";
import {getBase64, resizeImage} from "../Utilities/Utilities";

class NewItem extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            title: "",
            sku: "",
            desc: "",
            small_desc: "",
            price: "",
            photo: ""
        };

        this.fileSelector = null;

        this.buildFileSelector = this
            .buildFileSelector
            .bind(this);
    }

    buildFileSelector() {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('multiple', 'multiple');
        fileSelector.onchange = async() => {
            const rawImage = fileSelector.files[0];
            const resizedImage = await resizeImage(rawImage);
            getBase64(resizedImage, (e) => {
                this.setState({photo: e})
                console.log(e);
            });
        };
        return fileSelector;
    }

    componentDidMount()
    {
        this.fileSelector = this.buildFileSelector();
        console.log(this.props.presets)
        if(this.props.presets !== null)
        {
            this.setState(this.props.presets);
        }
    }

    limitNumber(e) {
        return e
            .target
            .value
            .replace(/[^0-9]/g, '');
    }

    isFilled()
    {
        return (this.state.title !== "" || this.state.sku !== "" || this.state.small_desc !== "" || this.state.price !== "" || this.state.title !== "" || this.state.photo !== "");
    }

    getItemData()
    {
        return {
            title: this.state.title,
            sku: this.state.sku,
            desc: this.state.desc,
            small_desc: this.state.small_desc,
            price: this.state.price,
            photo: this.state.photo
        };
    }

    getID()
    {
        return this.props.id;
    }

    render()
    {
        return (
            <div className="full-width">
                <div className="new-item-parent">
                    <div className="top-title-bar">
                        <div className="item-title">{this.state.title === ""
                                ? "Untitled"
                                : this.state.title}
                        </div>
                        <img
                            className="delete-icon"
                            src={DeleteIcon}
                            alt="delete"
                            onClick={() => this.props.onDelete(this.props.id)}/>
                    </div>
                    <div className="add-photo-btn" onClick={() => this.fileSelector.click()}>Upload Photo</div>
                    {this.state.photo !== ""
                        ? <img className="cover-img" src={this.state.photo} alt="cover"/>
                        : null}
                    <TextField
                        className="text-field"
                        label="Title"
                        variant="outlined"
                        value={this.state.title}
                        onChange={(e) => {
                        this.setState({title: e.target.value})
                    }}/>
                    <TextField
                        className="text-field"
                        label="SKU"
                        variant="outlined"
                        value={this.state.sku}
                        onChange={(e) => {
                        this.setState({sku: e.target.value})
                    }}/>
                    <TextField
                        className="text-field"
                        label="Description"
                        variant="outlined"
                        value={this.state.desc}
                        onChange={(e) => {
                        this.setState({desc: e.target.value})
                    }}/>
                    <TextField
                        className="text-field"
                        label="Small Description"
                        variant="outlined"
                        value={this.state.small_desc}
                        onChange={(e) => {
                        this.setState({small_desc: e.target.value})
                    }}/>
                    <TextField
                        className="text-field"
                        label="Price"
                        variant="outlined"
                        value={this.state.price}
                        InputProps={{
                        startAdornment: <InputAdornment position="start">RM</InputAdornment>
                    }}
                        onChange={(e) => {
                        this.setState({price: e.target.value})
                    }}/>
                </div>
            </div>
        );
    }
}

NewItem.propTypes = {
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    // id: PropTypes.string,
    presets: PropTypes.object
};

NewItem.defaultProps = {
    onSubmit: (e) => {
        console.log("Submitting :", e);
    },
    onDelete: (e) => {
        console.log("Deleting :", e);
    },
    id: 0,
    presets: null
};

export default NewItem;