import React from "react";
import "./MainPage.css";
import "./common.css";
import LogoWhite from "../assets/images/logo.png";
import VideoA from "../Videos/vid_1.mp4";
import PlayButton from "../Images/play.svg";
import PauseButton from "../Images/pause.svg";
import ExpandableFaq from "../Components/ExpandableFaq";
import VideoAPoster from "../Images/video_a_poster.png";
import Footer from "../Components/Footer";
import "./SurveyPage.css";
import Checked from "../Images/checked.svg";
import Unchecked from "../Images/unchecked.svg";
import RemovePortraitsIcon from "../Images/remove_portraits_icon.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Tooltip } from "@mui/material";
import { generateID, surveyInsert } from "../Utilities/Requests";
import { MdOutlineHelpOutline } from "react-icons/md";
import DragAndDropImageUploader from "../Components/DragAndDropImageUploader";
import ColorPicker from "../Components/ColorPicker";
import ItemPicker from "../Components/ItemPicker";
import ContactSupport from "../Images/whatsapp_logo.png";
import Model1 from "../Images/wtw_demo1.jpg";
import Model2 from "../Images/wtw_demo2.jpg";
import ReactHtmlParser from "react-html-parser";
import "./MainSeason2Page.css";

//new stuff
import NextButtonIcon from "../assets/images/next_button_icon.svg";
import PrevButtonIcon from "../assets/images/prev_button_icon.svg";
import Slider from "@ant-design/react-slick";
import { InfinitySpin } from "react-loader-spinner";
import Step1 from "../assets/images/step1.svg";
import Step2 from "../assets/images/step2.svg";
import Step3 from "../assets/images/step3.svg";

class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      mobileMode: false,
      vid1Playing: false,
      vid2Playing: false,
      language: "bm",
      hijab: true,
      design: 0,
      topAnchor: null,
      bottomAnchor: null,
      bodyTypeAnchor: null,
      skinToneAnchor: null,
      top: 0,
      bottom: 0,
      bodyType: 0,
      skinTone: 0,
      wishToReceive: [],
      outfit: [],
      workCasual: 0,
      name: "",
      ic: "",
      phone: "",
      email: "",
      address: "",
      ig: "",
      fb: "",
      occupation: "",
      height: "",
      weight: "",
      shoulder: "",
      bust: "",
      underbust: "",
      waist: "",
      hips: "",
      thigh: "",
      agree: false,
      showError: false,
      loading: false,
      showReturnError: false,
      emailError: false,
      noneSelectedError: false,
      portraitsError: false,
      portraits: {},
      currentLang: {},
      pattern: "",
      topPreference: "",
      sleevePreference: "",
      skirtPreference: "",
      dressPreference: "",
      pantsPreference: "",
      jeansPreference: "",
      referralCode: "",

      //new stuff
      images: [],
      images2: [],
    };

    this._isMounted = false;
    this.googleForm = React.createRef();

    this.playVideo = this.playVideo.bind(this);

    this.check = null;

    this.faqs = [
      {
        q: "What Is Try-At-Home?",
        a: "TRY-AT-HOME is a Try First, Pay Later service where we let our customers physically try our collections at the comfort of their homes before purchasing them. Our designers will hand-pick a few styles based on the customers’ profiles, and we’ll have them delivered to their door without any courier charges. They may then decide to purchase any of the items and return the rest.<br /><br />Through this service, we get to help our customers who want to look stylish in quality garments by giving them ample time to try and decide on the outfits and we will also provide a few styling tips.",
      },
      {
        q: "HOW DOES THE TRY-AT-HOME SERVICE WORK?",
        a:
          "Step 1 – Fill up the provided form and let us find your style.\nStep 2 – Items t" +
          "hat have been selected will be delivered to you.\nStep 3 – Try on the items. Kee" +
          "p what you like and return the rest within <b>seven (7) days</b>.\nStep 4 – Cont" +
          "act us and we will arrange the pickup. Complete your order and make your payment" +
          ".",
      },
      {
        q: "WHERE IS THIS SERVICE AVAILABLE?",
        a:
          "This service is currently available to customers with a billing address register" +
          "ed in West Malaysia only.",
      },
      {
        q: "Is this a subscription model?",
        a: "No subscription is required. You can use Try at Home by What to Wear as frequently as you want to, whenever you want to.",
      },
      {
        q: "HOW DO I PAY?",
        a: "We currently accept online banking, FPX, Credit card. Our customer services team will follow up with you via WhatsApp within 24 hours after form submission. They will guide you thru the checkout process.",
      },
      {
        q: "IS THE TRY-AT-HOME SERVICE VALUE FOR MONEY?",
        a: "Worth every penny! Because there will be an exclusive discount if you buy all items, and we will also have a promotion from time to time.",
      },
      {
        q: "HOW LONG DOES IT TAKE FOR MY TRY-AT-HOME PARCEL TO ARRIVE?",
        a: "Parcel takes about 3-5 days for processing and 1-3 business days to ship. Once they have been shipped out, you will receive a notification via WhatsApp or email with tracking information so you can know exactly when to expect your parcel!",
      },
      {
        q: "WHAT HAPPENS AFTER I’VE DONE TRYING?",
        a: "If you decide on a style, you can keep the one you love and return the rest. Contact us and fill in the return form to inform your final order and we will arrange for free reverse pickup.",
      },
      {
        q: "THE STYLES I TRIED ON DIDN’T WORK OUT FOR ME. CAN I ORDER A SECOND TRY-AT-HOME?",
        a: "Yes absolutely! If you’re looking to try on a new round of completely different styles, send us back the parcel from your first try-on and contact us to arrange another TRY-AT-HOME service.",
      },
      {
        q: "HOW DO I SEND THE PARCEL BACK?",
        a: "After having fun trying on your items for <b>seven (7) days</b>, put back the items that you want to return in the original packaging. Contact us and we will arrange for the pickup.",
      },
      {
        q: "WHAT IS THE RETURNS POLICY AFTER A PURCHASE IS MADE?",
        a: "Any items purchased through the TRY-AT-HOME service are not eligible for returns. Please contact our customer services team via WhatsApp or email if you have any queries about your purchase.",
      },
    ];

    this.wantWearColor = [];
    this.pattern = [];
    this.topPreference = [];
    this.sleevePreference = [];
    this.skirtPreference = [];
    this.dressPreference = [];
    this.pantsPreference = [];
    this.jeansPreference = [];
    this.uploadCount = 0;
    this.colorList = [
      "Black",
      "White",
      "Grey",
      "Gold",
      "Beige",
      "Brown",
      "Red",
      "Orange",
      "Yellow",
      "Green",
      "Teal",
      "Blue",
    ];
    this.colorCodeList = [
      "#000",
      "#fff",
      "#848484",
      "#c9a907",
      "#f5f5db",
      "#54260e",
      "#ba1e20",
      "#f9a700",
      "#ffff00",
      "#008000",
      "#008081",
      "#000080",
    ];
    this.sizeList = ["Select One", "XS", "S", "M", "L", "XL", "XXL", "XXXL"];
    this.wishToReceiveList = [
      "Dress",
      "Blouse",
      "Shirt",
      "Pants",
      "Jeans",
      "Skirt",
      "Shorts",
      "All",
    ];
    this.outfitList = [
      "Work",
      "Denim",
      "Casual",
      "Basic",
      "Weekend",
      "Statement",
      "All",
    ];
    this.workCasualList = [
      "Work",
      "Denim",
      "Casual",
      "Basic",
      "Weekend",
      "Statement",
      "All",
    ];
    this.bodyTypeList = [
      "Inverted triangle",
      "Pear",
      "Round",
      "Rectangle",
      "Hourglass",
    ];
    this.skinToneList = ["Fair", "Olive", "Tan", "Brown", "Dark Brown"];
    this.wearColorList = [
      { color: "black", label: "Black", textColor: "white" },
      { color: "white", label: "White", textColor: "#787a7c" },
      { color: "#606060", label: "Grey", textColor: "white" },
      { color: "#ffb202", label: "Gold", textColor: "white" },
      { color: "#ffd397", label: "Beige", textColor: "#7a7a7f" },
      { color: "#955a1e", label: "Brown", textColor: "white" },
      { color: "#afb573", label: "Khaki", textColor: "white" },
      { color: "#05ac58", label: "Green", textColor: "white" },
      { color: "#07ebe6", label: "Teal", textColor: "white" },
      { color: "#177bfc", label: "Blue", textColor: "white" },
      { color: "#d622ed", label: "Purple", textColor: "white" },
      { color: "#fc71fd", label: "Pink", textColor: "white" },
      { color: "#c90000", label: "Red", textColor: "white" },
      { color: "#ff8e00", label: "Orange", textColor: "white" },
      { color: "#ffe306", label: "Yellow", textColor: "#797a7d" },
    ];
    this.patternList = [
      { img: "/img/pattern_fabric/geometry.png", label: "Geometry" },
      { img: "/img/pattern_fabric/florals.png", label: "Floral" },
      { img: "/img/pattern_fabric/lace.png", label: "Lace" },
      { img: "/img/pattern_fabric/linen.png", label: "Linen" },
      { img: "/img/pattern_fabric/gingham.png", label: "Checked" },
      { img: "/img/pattern_fabric/plain.png", label: "Plain" },
      { img: "/img/pattern_fabric/polkadot.png", label: "Polka Dot" },
      { img: "/img/pattern_fabric/stripes.png", label: "Stripes" },
    ];
    this.topList = [
      { img: "/img/top/fitted.png", label: "Fitted" },
      { img: "/img/top/loose.png", label: "Loose" },
      { img: "/img/top/cropped.png", label: "Cropped" },
    ];
    this.sleeveList = [
      { img: "/img/sleeves/sleeveless.png", label: "Sleeveless" },
      { img: "/img/sleeves/offshoulder.png", label: "Off Shoulder" },
      { img: "/img/sleeves/puff.png", label: "Puff Sleeves" },
      { img: "/img/sleeves/flare.png", label: "Flare Sleeves" },
    ];
    this.skirtList = [
      { img: "/img/skirt/aline2.jpg", label: "A-line" },
      { img: "/img/skirt/tiered.png", label: "Tiered" },
      { img: "/img/skirt/pleated.png", label: "Pleated" },
      { img: "/img/skirt/pencil2.jpg", label: "Pencil" },
    ];
    this.dressList = [
      { img: "/img/dress/shirtdress.png", label: "Shirt Dress" },
      { img: "/img/dress/aline.png", label: "A-Line" },
      { img: "/img/dress/shift.png", label: "Shift" },
      { img: "/img/dress/relaxed.png", label: "Relaxed" },
      { img: "/img/dress/fitflare.png", label: "Fit & Flare" },
      { img: "/img/dress/midi.png", label: "Midi" },
      { img: "/img/dress/maxi.png", label: "Maxi" },
      { img: "/img/dress/wrap.png", label: "Wrap" },
      { img: "/img/dress/jumpsuit.png", label: "Jumpsuit" },
      { img: "/img/dress/short.png", label: "Short" },
    ];
    this.pantsList = [
      { img: "/img/pants/culottes.png", label: "Culottes" },
      { img: "/img/pants/wideleg.png", label: "Wide Leg" },
      { img: "/img/pants/tapered.png", label: "Tapered" },
      { img: "/img/pants/harem.png", label: "Harem" },
    ];
    this.jeansList = [
      { img: "/img/jeans/wideleg.png", label: "Wide Leg" },
      { img: "/img/jeans/straight.png", label: "Straight" },
      { img: "/img/jeans/slim.png", label: "Slim" },
      { img: "/img/jeans/skinny.png", label: "Skinny" },
      { img: "/img/jeans/ripped.png", label: "Ripped" },
      { img: "/img/jeans/boyfriend.png", label: "Boyfriend" },
    ];

    this.en = {
      desc: "Gets Personal Styling Consultation,<br/>Receive & Try Outfits at Your Door-step, For Free.",
      hdiw: "HOW DOES IT WORK?",
      step: "STEP",
      wiys: "What's your style?",
      fillup:
        "Fill up the Style Analysis form and let our stylist find your style.",
      sitback: "SIT BACK & RELAX!",
      sitback2:
        "Now all you need to do is wait. The items that have been selected for you will be delivered to you in no time!",
      havefun: "HAVE FUN TRYING THEM ON!",
      havefun2:
        "Once you’ve received the parcel, you can try on the outfits. Keep what you like and return the rest within&nbsp;<b>seven (7) days</b>.",
      timetobuy: "Time to buy",
      timetobuy2:
        "WhatsApp us at +6010-8989861 to complete your order and make your payment. If you have any items to return, let us know and we will arrange the pickup.",
      getstarted: "Kick Start Brand New Experience of Dressing Up",
      tfpl: "Try First, Pay Later",
      happy: "HAPPY CUSTOMERS",
      s_personal: "Personal Details",
      s_name: "Name*",
      s_ic: "Identity Card Number*",
      s_dob: "Date of Birth*",
      s_phone: "Contact Number*",
      s_email: "E-mail Address*",
      s_address: "Delivery Address*",
      s_ig: "Instagram ID",
      s_fb: "Facebook Link",
      s_occu: "Occupation",
      s_stylepref: "Style Preference *",
      s_body: "Body Measurement",
      s_height: "Height*",
      s_weight: "Weight*",
      s_shoulder: "Shoulder*",
      s_bust: "Bust*",
      s_waist: "Waist*",
      s_referral: "Referral Code",
      s_hips: "Hip*",
      s_size: "Size *",
      s_top: "Top Size*",
      s_bottom: "Bottom Size*",
      s_consent:
        "I hereby confirm that the information provided is true and accurate.",
      s_submit: "SUBMIT",
      s_selection: "Preferences",
      s_color_never_wear: "Colors Preference (Select one or more)* :",
      s_color2: "Can select more than 1 option",
      s_multiple: "Can select more than 1 option",
      s_design: "Design Preference*",
      s_product: "Products Preference* (Select one or more)",
      s_outfit: "Collection Preference* (Select one or more)",
      s_pattern: "Pattern & Fabric Preference* (Select one or more)",
      s_top_preference: "Top Preference* (Select one or more)",
      s_sleeve: "Sleeve Preference* (Select one or more)",
      s_skirt: "Skirt Preference* (Select one or more)",
      s_dress: "Dress Preference* (Select one or more)",
      s_pants: "Pants Preference* (Select one or more)",
      s_jeans: "Jeans Preference* (Select one or more)",
      s_portrait: "5. Portrait photos *",
      s_upload:
        "Please upload at least 2 recent <b>full portrait</b> photos to help us hand-pick clothes for you.",
      s_ighint: "Click into your IG Profile and it’s on the top!",
      s_fbhint:
        "Open your FB profile > click Menu > Scroll to Bottom > Copy Link",
      s_error_fill:
        "Please make sure you have filled up all the required information and confirm that the information provided is true and accurate.",
      s_error_correct:
        "Please make sure all the inputs have the correct information before proceed.",
      s_error_email: "Please make sure e-mail format is correct.",
      s_error_product:
        "Please select at least one product that you wish to receive.",
    };
    this.bm = {
      desc: "Gets Personal Styling Consultation,<br/>Receive & Try Outfits at Your Door-step, For Free.",
      hdiw: "MACAM MANA ORDER?",
      step: "LANGKAH",
      wiys: "Apa gaya anda?",
      fillup:
        "Isi borang Analisis Gaya dan biarkan stylist kami pilihkan gaya anda.",
      sitback: "DUDUK & REHAT!",
      sitback2:
        "Sekarang anda hanya perlu menunggu. Item yang telah dipilih untuk anda akan dihantar kepada anda dalam masa yang singkat!",
      havefun: "SELAMAT MENCUBANYA!",
      havefun2:
        "Sebaik sahaja anda terima bungkusan itu, anda boleh terus cuba pakaian yang anda dapat. Simpan mana yang anda suka dan pulangkan selebihnya dalam masa <b>tujuh (7) hari</b>.",
      timetobuy: "MASA UNTUK MEMBELI!",
      timetobuy2:
        "WhatsApp kami di +6010-8989861 untuk melengkapkan pesanan anda dan membuat pembayaran anda. Jika anda mempunyai sebarang item untuk dipulangkan, beritahu kami dan kami akan menguruskan pengambilan.",
      getstarted: "Get Started",
      tfpl: "Try First, Pay Later",
      happy: "PELANGGAN GEMBIRA",
      s_personal: "Data Peribadi",
      s_name: "1. Nama *",
      s_ic: "2. Nombor IC *",
      s_dob: "3. Tarikh Lahir *",
      s_phone: "4. Nombor Telefon *",
      s_email: "5. E-mail *",
      s_address: "6. Alamat Penuh *",
      s_ig: "7.ID Instagram *",
      s_fb: "8. ID Facebook *",
      s_occu: "9. Pekerjaan *",
      s_stylepref: "10. Pilihan Gaya *",
      s_body: "Ukuran Badan",
      s_height: "1. Tinggi (cm) *",
      s_weight: "2. Berat (kg) *",
      s_shoulder: "3. Bahu (inches) *",
      s_bust: "4. Dada (inches) *",
      s_waist: "5. Pinggang (inches) *",
      s_referral: "Referral Code",
      s_hips: "6. Punggung (inches) *",
      s_size: "7. Saiz *",
      s_top: "Bahagian Atas",
      s_bottom: "Bahagian Bawah",
      s_consent:
        "Saya dengan ini mengesahkan bahawa maklumat yang diberikan adalah benar dan tepat.",
      s_submit: "HANTAR",
      s_selection: "Gaya Pilihan",
      s_color_never_wear: "1. Warna apakah yang anda tidak akan pakai? *",
      s_color2:
        "Pilihkan warna yang anda tidak gemari. Boleh pilih lebih dari satu.",
      s_multiple: "Boleh pilih lebih dari satu.",
      s_design: "2. Rekaan pakaian yang digemari: *",
      s_product: "3. Jenis pakaian yang diingini: *",
      s_outfit: "4. Jenis Gaya yang diinginkan: *",
      s_portrait: "5. Gambar anda: *",
      s_upload:
        "Sila hantarkan 2 gambar potrait terbaru anda untuk memudahkan urusan pemilihan baju kami. Eg:",
      s_ighint: "Klik dalam profil IG dan ialah di bahagian atas.",
      s_fbhint:
        "Klik dalam profil FB > klik Menu > scroll ke bawah > Copy Link",
      s_error_fill: "Sila pastikan semua infomasi sudah diisi.",
      s_error_correct: "Sila pastikan semua infomasi sudah diisi.",
      s_error_email: "Sila pastikan emel yang diisi adalah sesuai.",
      s_error_product:
        "Sila pilih sekurangnya satu jenis pakaian yang diinginkan.",
    };
    this.state.currentLang = this.en;
    this._isMounted = false;
    this.googleForm = React.createRef();
    this.sent25 = false;
    this.sent50 = false;
    this.sent75 = false;
    this.sent100 = false;

    this.openTopSize = this.openTopSize.bind(this);
    this.closeTopSize = this.closeTopSize.bind(this);
    this.openBottomSize = this.openBottomSize.bind(this);
    this.closeBottomSize = this.closeBottomSize.bind(this);
    this.openBodyType = this.openBodyType.bind(this);
    this.closeBodyType = this.closeBodyType.bind(this);
    this.openSkinTone = this.openSkinTone.bind(this);
    this.closeSkinTone = this.closeSkinTone.bind(this);
    this.handleWishToReceive = this.handleWishToReceive.bind(this);
    this.handleOutfit = this.handleOutfit.bind(this);
    this.handleWorkCasual = this.handleWorkCasual.bind(this);
    this.checkSubmit = this.checkSubmit.bind(this);
    this.checkString = this.checkString.bind(this);
    this.checkInt = this.checkInt.bind(this);
    this.submit = this.submit.bind(this);
    this.listenToScroll = this.listenToScroll.bind(this);
    this.updateLang = this.updateLang.bind(this);

    //new stuff
    this.playVideo = this.playVideo.bind(this);
    this._isMounted = false;
    this.updateDimensions = this.updateDimensions.bind(this);
    this.tryNow = this.tryNow.bind(this);
    //new stuff
    this.steps = [
      {
        image: Step1,
        title: "Tell us about you",
        description:
          "Take a quick and easy questionare to help us learn about you and what you love",
      },
      {
        image: Step2,
        title: "Get pieces picked just for you",
        description:
          "Consult with our stylist to pick on pieces that reflect your style, fit and price point.",
      },
      {
        image: Step3,
        title: "Receive Clothings At Your Door-step",
        description:
          "Try & keep what you like, pay for what you picked, and return the rest to us.",
      },
    ];
  }

  resizeIframe() {
    // if (this.googleForm.current !== null) {     this.googleForm.current.height =
    // '0px';
    // console.log(this.googleForm.current.contentWindow.document.documentElement.sc
    // r ollHeight) } this.googleForm.current.style.height =
    // this.googleForm.current.contentWindow.document.documentElement.scrollHeight +
    // 'px';
  }

  carouselShowItemCount() {
    // console.log(this.state.width, 320);
    return Math.max(1, Math.floor(Math.max(this.state.width, 1) / 512));
  }

  playVideo(vid) {
    var state = false;
    if (vid === 1) {
      state = !this.state.vid1Playing;
      this.updateState({ vid1Playing: state });
    }
    if (vid === 2) {
      state = !this.state.vid2Playing;
      this.updateState({ vid2Playing: state });
    }
    var vidElement = window.document.getElementById("vid_" + vid);
    if (state) {
      vidElement.play();
      window.sendTracking("video" + vid, "play", "1");
    } else {
      vidElement.pause();
    }
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  async getCarouselImages() {
    let response = await fetch(
      "https://try.whattowear.com.my/img/season2/get_image_carousel_v2.php"
    );
    let json = await response.json();
    // repeat json.data x times to make it longer, at least make it has 10 items

    let images = json.data;
    let repeat = 10;
    while (repeat > 0) {
      images = images.concat(json.data);
      repeat--;
    }
    this.updateState({ images: images });
  }

  async getCarouselImages2() {
    let response = await fetch(
      "https://try.whattowear.com.my/img/season2/get_image_carousel_v2_2.php"
    );
    let json = await response.json();
    // repeat json.data x times to make it longer, at least make it has 10 items

    let images = json.data;
    let repeat = 10;
    while (repeat > 0) {
      images = images.concat(json.data);
      repeat--;
    }
    this.updateState({ images2: images });
  }

  tryNow() {
    document.getElementById("separator").scrollIntoView({ behavior: "smooth" });
  }

  contactNow() {
    var url = "https://api.whatsapp.com/send/?phone=60136838383";
    window.location.href = url;
  }
  openTopSize = event => {
    this.setState({ topAnchor: event.currentTarget });
  };

  closeTopSize(selectedItem) {
    if (selectedItem >= 0) {
      this.updateState({ topAnchor: null, top: selectedItem });
    } else {
      this.updateState({ topAnchor: null });
    }
  }

  openBottomSize = event => {
    this.setState({ bottomAnchor: event.currentTarget });
  };

  closeBottomSize(selectedItem) {
    if (selectedItem >= 0) {
      this.updateState({ bottomAnchor: null, bottom: selectedItem });
    } else {
      this.updateState({ bottomAnchor: null });
    }
  }

  openBodyType = event => {
    this.setState({ bodyTypeAnchor: event.currentTarget });
  };

  closeBodyType(selectedItem) {
    if (selectedItem >= 0) {
      this.updateState({ bodyTypeAnchor: null, bodyType: selectedItem });
    } else {
      this.updateState({ bodyTypeAnchor: null });
    }
  }

  openSkinTone = event => {
    this.setState({ skinToneAnchor: event.currentTarget });
  };

  closeSkinTone(selectedItem) {
    if (selectedItem >= 0) {
      this.updateState({ skinToneAnchor: null, skinTone: selectedItem });
    } else {
      this.updateState({ skinToneAnchor: null });
    }
  }

  updateState(state) {
    if (this._isMounted) {
      this.setState(state);
    }
  }

  handleResize = e => {
    this.updateState({
      mobileMode: window.innerWidth < 650,
      currentWidth: window.innerWidth,
      currentHeight: window.innerHeight,
    });
  };

  handleWishToReceive(i) {
    let currentWishToReceive = this.state.wishToReceive;

    if (currentWishToReceive[i] === 1) {
      currentWishToReceive[i] = 0;
      this.updateState({ wishToReceive: currentWishToReceive });
    } else {
      currentWishToReceive[i] = 1;
      this.updateState({ wishToReceive: currentWishToReceive });
    }
    //console.log("wishtoreceive" + JSON.stringify(this.state.wishToReceive));
  }
  handleOutfit(i) {
    let currentOutfit = this.state.outfit;

    if (currentOutfit[i] === 1) {
      currentOutfit[i] = 0;
      this.updateState({ outfit: currentOutfit });
    } else {
      currentOutfit[i] = 1;
      this.updateState({ outfit: currentOutfit });
    }
    //console.log("wishtoreceive" + JSON.stringify(this.state.wishToReceive));
  }
  handleWorkCasual(i) {
    let currentSelection = this.state.workCasual;

    if (currentSelection[i] === 1) {
      currentSelection[i] = 0;
      this.updateState({ workCasual: currentSelection });
    } else {
      currentSelection[i] = 1;
      this.updateState({ workCasual: currentSelection });
    }
    // console.log("workcasual" + JSON.stringify(this.state.workCasual));
  }
  updateLang(lang) {
    if (lang === "en") {
      this.updateState({ currentLang: this.en });
    } else {
      this.updateState({ currentLang: this.bm });
    }
    console.log("updating to : " + lang);
  }
  cleanString(s) {
    return s.replace(/[^a-zA-Z ]/g, "");
  }

  componentDidMount() {
    this._isMounted = true;
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    window.sendTracking("enter", "page", "main");

    window.addEventListener("scroll", this.listenToScroll);
    window.fb_pixel_track_survey();
    this.getCarouselImages();
    this.getCarouselImages2();
    this._isMounted = true;
    // listen to resize event
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    if (scrolled > 0.95) {
      if (!this.sent100) {
        window.sendTracking("scroll-survey-100", "survey", "100%");
        this.sent100 = true;
      }
    } else if (scrolled > 0.75) {
      if (!this.sent75) {
        window.sendTracking("scroll-survey-75", "survey", "75%");
        this.sent75 = true;
      }
    } else if (scrolled > 0.5) {
      if (!this.sent50) {
        window.sendTracking("scroll-survey-50", "survey", "50%");
        this.sent50 = true;
      }
    } else if (scrolled > 0.25) {
      if (!this.sent25) {
        window.sendTracking("scroll-survey-25", "survey", "25%");
        this.sent25 = true;
      }
    }

    /*
        var survey_position = document.getElementById("separator").offsetTop - window.innerHeight;
        //console.log("survey position: "+ survey_position + "  winscroll " + winScroll);
        if(winScroll > survey_position ){
            document.getElementById("float-bottom").style.display = "none";
        }else{
            document.getElementById("float-bottom").style.display = "block";
        }*/
  };

  checkSubmit() {
    let toCheck = [
      this.state.name,
      //this.state.ic,
      this.state.phone,
      this.state.email,
      /*this.state.address,
            this.state.height,
            this.state.weight,
            this.state.shoulder,
            this.state.bust,
            this.state.waist,
            this.state.hips,
            this.state.pattern,
            this.state.topPreference,
            this.state.sleevePreference,
            this.state.skirtPreference,
            this.state.dressPreference,
            this.state.pantsPreference,
            this.state.jeansPreference,*/
    ];
    var pass = true;
    if (this.state.top === 0 || this.state.bottom === 0) {
      console.log("top or bottom false");
      // pass = false;
    }
    toCheck.forEach(eachToCheck => {
      console.log("checking: " + eachToCheck);
      if (!this.checkString(eachToCheck)) {
        console.log("checkstring: false");
        pass = false;
        return false;
      }
    });
    var noneSelectedError = false;
    if (
      this.state.wishToReceive.length === 0 ||
      !this.state.wishToReceive.includes(1)
    ) {
      console.log("wishtorseive: false");
      // pass = false;
      // noneSelectedError = true;
    }
    var email_to_check = this.state.email.trim();
    this.setState({ email: email_to_check });
    var emailError = !this.validateEmail(email_to_check);
    if (emailError) {
      console.log("email: false");
      pass = false;
    }
    if (!this.state.agree) {
      console.log("agree: false");
      pass = false;
    }
    var portraitsError = false;

    if (Object.keys(this.state.portraits).length < 2) {
      console.log("portaits: false");
      //  portraitsError = true;
      //  pass = false;
    }
    if (!pass) {
      this.setState({
        showError: true,
        emailError: emailError,
        noneSelectedError: noneSelectedError,
        portraitsError: portraitsError,
      });
    }
    return pass;
  }

  checkString(s) {
    return s.replace(" ", "") !== "";
  }

  checkInt(i) {
    return /^\d+$/.test(i);
  }

  intOnly(i) {
    return i.replace(/[^\d.-]/g, "");
  }

  validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  async submit() {
    if (this.checkSubmit()) {
      this.setState({ loading: true });

      var selectedWishToReceiveArray = this.state.wishToReceive;
      var newSelectedArray = [];
      var selectedWishToReceive = "";
      for (var i = 0; i < selectedWishToReceiveArray.length; i++) {
        if (selectedWishToReceiveArray[i] === 1) {
          newSelectedArray.push(this.wishToReceiveList[i]);
        }
      }
      selectedWishToReceive = newSelectedArray.join(", ");

      var selectedOutfitArray = this.state.outfit;
      newSelectedArray = [];
      var selectedOutfit = "";
      for (var j = 0; j < selectedOutfitArray.length; j++) {
        if (selectedOutfitArray[j] === 1) {
          newSelectedArray.push(this.outfitList[j]);
        }
      }
      selectedOutfit = newSelectedArray.join(", ");

      var portrait = [];
      Object.keys(this.state.portraits).forEach(eachPortrait => {
        portrait.push(this.state.portraits[eachPortrait]);
      });
      var selectedDesign = "Printed";
      switch (this.state.design) {
        default:
        case 0:
          selectedDesign = "Printed";
          break;
        case 1:
          selectedDesign = "Plain";
          break;
        case 2:
          selectedDesign = "Both";
          break;
      }
      var selectedWorkCasual = "Work";
      switch (this.state.workCasual) {
        default:
        case 0:
          selectedWorkCasual = "Work";
          break;
        case 1:
          selectedWorkCasual = "Casual";
          break;
        case 2:
          selectedWorkCasual = "Both";
          break;
      }
      let surveyData = {
        name: this.state.name,
        nric: this.state.ic,
        dob: this.state.ic.substring(0, 6),
        phone: this.state.phone,
        address: this.state.address,
        email: this.state.email,
        ig_id: this.state.ig,
        fb_id: this.state.fb,
        occupation: this.state.occupation,
        style_pref: this.state.hijab ? "Hijab" : "Non-Hijab",
        design: selectedDesign,
        height: this.state.height,
        weight: this.state.weight,
        shoulder: this.state.shoulder,
        bust: this.state.bust,
        waist: this.state.waist,
        hips: this.state.hips,
        size_top: this.sizeList[this.state.top],
        size_bottom: this.sizeList[this.state.bottom],
        wish_to_receive: selectedWishToReceive,
        work_casual: selectedWorkCasual,
        agreement: true,
        portrait: portrait,
        never_wear_color: this.wantWearColor,
        current_outfit: selectedOutfit,
        pattern: this.state.pattern,
        top_preference: this.state.topPreference,
        sleeve_preference: this.state.sleevePreference,
        skirt_preference: this.state.skirtPreference,
        dress_preference: this.state.dressPreference,
        pants_preference: this.state.pantsPreference,
        jeans_preference: this.state.jeansPreference,
        referral_code: this.state.referralCode,
      };

      //console.log("surveyData :" + JSON.stringify(surveyData));

      let id = generateID(9);
      let redirect = `https://try.whattowear.com.my/survey/pay/pay.php?r=${id}`;
      var result = await surveyInsert(id, surveyData);
      if (result.status === true) {
        window.fb_pixel_track_complete_survey(id);
        window.sendTracking("navigate", "payment", "1");
        window.location.href = redirect;
      } else {
        this.setState({ loading: false, showReturnError: true });
      }
    }
  }

  render() {
    return (
      <div style={{ maxWidth: "100vw" }}>
        <div className="landing-page-parent">
          <div className="landing-page-top-bar">
            <a href="https://www.whattowear.com.my">
              <img style={{ height: 48 }} src={LogoWhite} alt="Logo" />
            </a>
          </div>

          <div className="landing-page-title-bar">
            <span style={{ color: "#333333" }}>
              <a
                style={{
                  color: "unset",
                  border: "unset",
                  textDecoration: "unset",
                }}
                href="https://www.whattowear.com.my">
                Home /
              </a>
            </span>
            <span style={{ color: "#6c757d" }}>Try At Home</span>
          </div>

          <div className="landing-page-main">
            <div className="landing-page-main-title">Try-At-Home</div>
            <div className="landing-page-main-subtitle">
              We provide Personal Styling Consultation <br />
              Receive& Try Outfits at Your Door-step, For Free. <br />
            </div>
            <div style={{ margin: "1em" }}>
              <div
                className="landing-page-try-now-button"
                onClick={this.tryNow}>
                Try Now
              </div>
            </div>
            <div className="video-parent" onClick={() => this.playVideo(1)}>
              <video
                className="video-wide"
                playsInline
                poster={VideoAPoster}
                id="vid_1">
                <source src={VideoA} type="video/mp4" />
              </video>
              <img
                className={
                  this.state.vid1Playing ? "pause-button" : "play-button"
                }
                src={this.state.vid1Playing ? PauseButton : PlayButton}
                alt="play-pause-button"
              />
            </div>
          </div>

          <div className="landing-page-steps">
            <div className="landing-page-title m-b-l">
              How Try-At-Home works
            </div>

            <div className="steps-parent">
              {this.steps.map((step, index) => {
                return (
                  <div key={`step-${index}`} className="step-container">
                    <div className="step-image">
                      <img src={step.image} alt="" />
                    </div>
                    <div>
                      <div className="step-title">{step.title}</div>
                      <div className="step-description">{step.description}</div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div style={{ margin: "4em" }}>
              <div
                className="landing-page-try-now-button"
                onClick={this.tryNow}>
                Try Now
              </div>
            </div>
          </div>

          <div className="landing-page-customization">
            <div
              className="landing-page-title"
              style={{ paddingBottom: "1em" }}>
              Tell us what you like <br />
              we will find what you love
            </div>

            <div className="landing-page-main-subtitle">
              We provide Personal Styling Consultation <br />
              Receive& Try Outfits at Your Door-step, For Free. <br />
            </div>

            <div className="landing-page-customization-image-container">
              <div style={{ maxWidth: "100vw" }}>
                {this.state.images.length > 0 ? (
                  <Slider
                    dots={false}
                    arrows={true}
                    infinite={true}
                    speed={750}
                    autoplaySpeed={5000}
                    autoplay={true}
                    slidesToShow={this.carouselShowItemCount()}
                    slidesToScroll={1}
                    pauseOnHover={false}
                    nextArrow={
                      <div style={{ width: 48, height: 48 }}>
                        <img src={NextButtonIcon} alt="" />
                      </div>
                    }
                    prevArrow={
                      <div style={{ width: 48, height: 48 }}>
                        <img src={PrevButtonIcon} alt="" />
                      </div>
                    }>
                    {this.state.images.map((image, index) => (
                      <a
                        href={image.url.length > 0 ? image.url : "#try-now"}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="customization-image-container"
                        style={{ objectFit: "cover" }}
                        key={index}>
                        <img
                          className="customization-image"
                          src={image.image}
                          alt=""
                        />
                      </a>
                    ))}
                  </Slider>
                ) : (
                  <InfinitySpin color="#dfcaba" />
                )}
              </div>
            </div>

            <div
              className="landing-page-title"
              style={{ paddingBottom: "1em" }}>
              Customer testimonial
            </div>
            <div className="landing-page-main-subtitle">
              #wtwtoday
            </div>
            <div className="landing-page-customization-image-container">
              <div style={{ maxWidth: "100vw" }}>
                {this.state.images2.length > 0 ? (
                  <Slider
                    dots={false}
                    arrows={true}
                    infinite={true}
                    speed={750}
                    autoplaySpeed={5000}
                    autoplay={true}
                    slidesToShow={this.carouselShowItemCount()}
                    slidesToScroll={1}
                    pauseOnHover={false}
                    nextArrow={
                      <div style={{ width: 48, height: 48 }}>
                        <img src={NextButtonIcon} alt="" />
                      </div>
                    }
                    prevArrow={
                      <div style={{ width: 48, height: 48 }}>
                        <img src={PrevButtonIcon} alt="" />
                      </div>
                    }>
                    {this.state.images2.map((image, index) => (
                      <a
                        href={image.url.length > 0 ? image.url : "#try-now"}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="customization-image-container"
                        style={{ objectFit: "cover" }}
                        key={index}>
                        <img
                          className="customization-image"
                          src={image.image}
                          alt=""
                        />
                      </a>
                    ))}
                  </Slider>
                ) : (
                  <InfinitySpin color="#dfcaba" />
                )}
              </div>
            </div>
          </div>

          <div
            id="landing-page-questionaire"
            className="landing-page-questionaire">
            <div
              className="landing-page-title"
              style={{ paddingBottom: "1em" }}>
              Having trouble with
              <br />
              filling up the questionaire?
            </div>

            <div className="landing-page-main-subtitle">
              No Worries, Schedule a 5-minutes call with
              <br />
              out Try-At-Home Stylist, we will get in touch soon <br />
            </div>

            <div style={{ margin: "3em 2em" }}>
              <div
                className="landing-page-try-now-button"
                onClick={this.contactNow}>
                Schedule Call
              </div>
            </div>
          </div>
          <div className="content">
            <div className="float-top">
              <div
                className="top-button-inline"
                onClick={() => {
                  this.updateLang("bm");
                }}>
                BM
              </div>
              <div
                className="top-button-inline"
                onClick={() => {
                  this.updateLang("en");
                }}>
                EN
              </div>
            </div>
            <img
              className="float-support"
              alt=""
              src={ContactSupport}
              onClick={() => {
                window.open(
                  "https://api.whatsapp.com/send/?phone=60136838383",
                  "_blank"
                );
              }}
            />

            <div className="faq-parent margin-top-l">
              <div className="faq-title">F.A.Q</div>
              {this.faqs.map((eachFaq, index) => (
                <ExpandableFaq
                  key={`faq-${index}`}
                  question={eachFaq.q.toLowerCase()}
                  answer={eachFaq.a}
                />
              ))}
            </div>

            <div id="separator" className="separator"></div>
            <div className="display-title">
              {this.state.currentLang.getstarted}
            </div>
            <div className="survey-center-item">
              {/*<div className="mini-highlight-text">

                            <img className="input-icon" src={NameIcon} alt="icon"/>
                            <div>
                                A. {this.state.currentLang.s_personal}:</div>
                        </div>*/}
              <div className="isolate full-width dis-content">
                <div className="all-input">
                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_name}
                        </div>
                      </li>
                      <li className="li-input-input">
                        <input
                          type="text"
                          placeholder=""
                          value={this.state.name}
                          onInput={e =>
                            this.setState({ name: e.currentTarget.value })
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_ic}
                        </div>
                      </li>
                      <li className="li-input-input">
                        <input
                          type="text"
                          placeholder=""
                          value={this.state.ic}
                          onInput={e =>
                            this.setState({ ic: e.currentTarget.value })
                          }
                        />
                      </li>
                    </ul>
                  </div>

                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_phone}
                        </div>
                      </li>
                      <li className="li-input-input">
                        <input
                          type="text"
                          placeholder=""
                          value={this.state.phone}
                          onInput={e =>
                            this.setState({ phone: e.currentTarget.value })
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_email}
                        </div>
                      </li>
                      <li className="li-input-input">
                        <input
                          type="text"
                          placeholder=""
                          value={this.state.email}
                          onInput={e =>
                            this.setState({ email: e.currentTarget.value })
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_address}
                        </div>
                      </li>
                      <li className="li-input-input">
                        <input
                          type="text"
                          placeholder=""
                          value={this.state.address}
                          onInput={e =>
                            this.setState({ address: e.currentTarget.value })
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_ig}
                          <Tooltip
                            title={this.state.currentLang.s_ighint}
                            enterTouchDelay={0}>
                            <IconButton style={{ color: "#955A1E" }}>
                              <MdOutlineHelpOutline />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </li>
                      <li className="li-input-input">
                        <input
                          type="text"
                          placeholder="Optional"
                          value={this.state.ig}
                          onInput={e =>
                            this.setState({ ig: e.currentTarget.value })
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_fb}
                          <Tooltip
                            title={this.state.currentLang.s_fbhint}
                            enterTouchDelay={0}>
                            <IconButton style={{ color: "#955A1E" }}>
                              <MdOutlineHelpOutline />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </li>
                      <li className="li-input-input">
                        <input
                          type="text"
                          placeholder="Optional"
                          value={this.state.fb}
                          onInput={e =>
                            this.setState({ fb: e.currentTarget.value })
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_occu}
                        </div>
                      </li>
                      <li className="li-input-input">
                        <input
                          type="text"
                          placeholder="Optional"
                          value={this.state.occupation}
                          onInput={e =>
                            this.setState({ occupation: e.currentTarget.value })
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_height}
                        </div>
                      </li>
                      <li className="li-input-input">
                        <input
                          type="text"
                          placeholder="cm"
                          value={this.state.height}
                          onInput={e =>
                            this.setState({ height: e.currentTarget.value })
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_weight}
                        </div>
                      </li>
                      <li className="li-input-input">
                        <input
                          type="text"
                          placeholder="kg"
                          value={this.state.weight}
                          onInput={e =>
                            this.setState({ weight: e.currentTarget.value })
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_shoulder}
                        </div>
                      </li>
                      <li className="li-input-input">
                        <input
                          type="text"
                          placeholder="Inches"
                          value={this.state.shoulder}
                          onInput={e =>
                            this.setState({ shoulder: e.currentTarget.value })
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_bust}
                        </div>
                      </li>
                      <li className="li-input-input">
                        <input
                          type="text"
                          placeholder="Inches"
                          value={this.state.bust}
                          onInput={e =>
                            this.setState({ bust: e.currentTarget.value })
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_waist}
                        </div>
                      </li>
                      <li className="li-input-input">
                        <input
                          type="text"
                          placeholder="Inches"
                          value={this.state.waist}
                          onInput={e =>
                            this.setState({ waist: e.currentTarget.value })
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_hips}
                        </div>
                      </li>
                      <li className="li-input-input">
                        <input
                          type="text"
                          placeholder="Inches"
                          value={this.state.hips}
                          onInput={e =>
                            this.setState({ hips: e.currentTarget.value })
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_top}
                        </div>
                      </li>
                      <li className="li-input-input">
                        <div className="btn-wrap">
                          <div
                            className="dropdown-btn"
                            onClick={this.openTopSize}>
                            {this.sizeList[this.state.top]}
                          </div>
                          <Menu
                            anchorEl={this.state.topAnchor}
                            open={Boolean(this.state.topAnchor)}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}>
                            {this.sizeList.map((eachSize, i) => (
                              <MenuItem
                                key={"key" + i}
                                onClick={() => {
                                  this.closeTopSize(i);
                                }}>
                                {eachSize}
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent">
                    <ul className="ul-input">
                      <li className="li-input-title">
                        <div className="input-title">
                          {this.state.currentLang.s_bottom}
                        </div>
                      </li>
                      <li className="li-input-input">
                        <div className="btn-wrap">
                          <div
                            className="dropdown-btn"
                            onClick={this.openBottomSize}>
                            {this.sizeList[this.state.bottom]}
                          </div>
                          <Menu
                            anchorEl={this.state.bottomAnchor}
                            open={Boolean(this.state.bottomAnchor)}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}>
                            {this.sizeList.map((eachSize, i) => (
                              <MenuItem
                                key={"key" + i}
                                onClick={() => {
                                  this.closeBottomSize(i);
                                }}>
                                {eachSize}
                              </MenuItem>
                            ))}
                          </Menu>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="survey-question">
                    {this.state.currentLang.s_color_never_wear}
                  </div>

                  <div className="survey-hint">
                    {this.state.currentLang.s_color2}
                  </div>
                  <div
                    className="color-list"
                    style={{
                      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                    }}>
                    {this.wearColorList.map(eachColor => {
                      return (
                        <ColorPicker
                          size="80px"
                          label={eachColor["label"]}
                          color={eachColor["color"]}
                          textColor={eachColor["textColor"]}
                          onClick={(newState, color) => {
                            if (newState) {
                              this.wantWearColor.push(color);
                            } else {
                              this.wantWearColor.splice(
                                this.wantWearColor.indexOf(color),
                                1
                              );
                            }
                            console.log(this.wantWearColor);
                          }}
                        />
                      );
                    })}
                  </div>

                  <div className="input-parent multiple-choice">
                    <div className="survey-question">
                      {this.state.currentLang.s_design}
                    </div>
                    <div className="hori center-everything pad-l">
                      <img
                        className="check-btn"
                        alt="check-btn"
                        onClick={() => {
                          this.setState({ design: 0 });
                        }}
                        src={this.state.design === 0 ? Checked : Unchecked}
                      />
                      <div
                        className="answer"
                        onClick={() => {
                          this.setState({ design: 0 });
                        }}>
                        Printed
                      </div>
                    </div>
                    <div className="hori center-everything pad-l">
                      <img
                        className="check-btn"
                        alt="check-btn"
                        onClick={() => {
                          this.setState({ design: 1 });
                        }}
                        src={this.state.design === 1 ? Checked : Unchecked}
                      />
                      <div
                        className="answer"
                        onClick={() => {
                          this.setState({ design: 1 });
                        }}>
                        Plain
                      </div>
                    </div>
                    <div className="hori center-everything pad-l">
                      <img
                        className="check-btn"
                        alt="check-btn"
                        onClick={() => {
                          this.setState({ design: 2 });
                        }}
                        src={this.state.design === 2 ? Checked : Unchecked}
                      />
                      <div
                        className="answer"
                        onClick={() => {
                          this.setState({ design: 2 });
                        }}>
                        Both
                      </div>
                    </div>
                  </div>

                  <div className="input-parent multiple-choice">
                    <div className="survey-question">
                      {this.state.currentLang.s_product}
                    </div>
                    <ul className="ul-input">
                      <li className="li-input-input">
                        {this.wishToReceiveList.map((eachWishToReceive, i) => (
                          <div
                            className="hori center-everything pad-l"
                            key={"wtrkey" + i}>
                            <img
                              className={"check-btn wtrkey" + i}
                              alt={
                                "check-btn state " + this.state.wishToReceive[i]
                              }
                              onClick={() => {
                                this.handleWishToReceive(i);
                              }}
                              src={
                                this.state.wishToReceive[i] === 1
                                  ? Checked
                                  : Unchecked
                              }
                            />
                            <div
                              className="answer"
                              onClick={() => {
                                this.handleWishToReceive(i);
                              }}>
                              {eachWishToReceive}
                            </div>
                          </div>
                        ))}
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent multiple-choice">
                    <div className="survey-question">
                      {this.state.currentLang.s_outfit}
                    </div>
                    <ul className="ul-input">
                      <li className="li-input-input">
                        {this.outfitList.map((eachOutfit, i) => (
                          <div
                            className="hori center-everything pad-l"
                            key={"wtrkey" + i}>
                            <img
                              className={"check-btn wtrkey" + i}
                              alt={"check-btn state " + this.state.outfit[i]}
                              onClick={() => {
                                this.handleOutfit(i);
                              }}
                              src={
                                this.state.outfit[i] === 1 ? Checked : Unchecked
                              }
                            />
                            <div
                              className="answer"
                              onClick={() => {
                                this.handleOutfit(i);
                              }}>
                              {eachOutfit}
                            </div>
                          </div>
                        ))}
                      </li>
                    </ul>
                  </div>
                  <div className="input-parent multiple-choice">
                    <div className="survey-question">
                      {this.state.currentLang.s_pattern}
                    </div>

                    <div className="column_4_list">
                      {this.patternList.map(eachItem => {
                        return (
                          <ItemPicker
                            size="80"
                            label={eachItem["label"]}
                            img={eachItem["img"]}
                            onClick={(newState, itemLabel) => {
                              if (newState) {
                                this.pattern.push(itemLabel);
                              } else {
                                this.pattern.splice(
                                  this.pattern.indexOf(itemLabel),
                                  1
                                );
                              }
                              this.setState({
                                pattern: this.pattern.join(", "),
                              });
                              //console.log(this.pattern);
                            }}
                          />
                        );
                      })}
                    </div>
                    {this.state.pattern !== "" ? (
                      <div className="selected_items">
                        <b>Selected:</b> {this.state.pattern}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="input-parent multiple-choice">
                    <div className="survey-question">
                      {this.state.currentLang.s_top_preference}
                    </div>

                    <div
                      className="column_4_list"
                      style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
                      {this.topList.map(eachItem => {
                        return (
                          <ItemPicker
                            size="140"
                            circleOrSquare="square"
                            label={eachItem["label"]}
                            img={eachItem["img"]}
                            onClick={(newState, itemLabel) => {
                              if (newState) {
                                this.topPreference.push(itemLabel);
                              } else {
                                this.topPreference.splice(
                                  this.topPreference.indexOf(itemLabel),
                                  1
                                );
                              }
                              this.setState({
                                topPreference: this.topPreference.join(", "),
                              });
                            }}
                          />
                        );
                      })}
                    </div>
                    {this.state.topPreference !== "" ? (
                      <div className="selected_items">
                        <b>Selected:</b> {this.state.topPreference}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="input-parent multiple-choice">
                    <div className="survey-question">
                      {this.state.currentLang.s_sleeve}
                    </div>

                    <div className="column_4_list">
                      {this.sleeveList.map(eachItem => {
                        return (
                          <ItemPicker
                            size="140"
                            circleOrSquare="square"
                            label={eachItem["label"]}
                            img={eachItem["img"]}
                            onClick={(newState, itemLabel) => {
                              if (newState) {
                                this.sleevePreference.push(itemLabel);
                              } else {
                                this.sleevePreference.splice(
                                  this.sleevePreference.indexOf(itemLabel),
                                  1
                                );
                              }
                              this.setState({
                                sleevePreference:
                                  this.sleevePreference.join(", "),
                              });
                            }}
                          />
                        );
                      })}
                    </div>
                    {this.state.sleevePreference !== "" ? (
                      <div className="selected_items">
                        <b>Selected:</b> {this.state.sleevePreference}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="input-parent multiple-choice">
                    <div className="survey-question">
                      {this.state.currentLang.s_skirt}
                    </div>

                    <div
                      className="column_4_list"
                      style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
                      {this.skirtList.map(eachItem => {
                        return (
                          <ItemPicker
                            size="140"
                            circleOrSquare="square"
                            label={eachItem["label"]}
                            img={eachItem["img"]}
                            onClick={(newState, itemLabel) => {
                              if (newState) {
                                this.skirtPreference.push(itemLabel);
                              } else {
                                this.skirtPreference.splice(
                                  this.skirtPreference.indexOf(itemLabel),
                                  1
                                );
                              }
                              this.setState({
                                skirtPreference:
                                  this.skirtPreference.join(", "),
                              });
                            }}
                          />
                        );
                      })}
                    </div>
                    {this.state.sleevePreference !== "" ? (
                      <div className="selected_items">
                        <b>Selected:</b> {this.state.skirtPreference}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="input-parent multiple-choice">
                    <div className="survey-question">
                      {this.state.currentLang.s_dress}
                    </div>

                    <div
                      className="column_4_list"
                      style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }}>
                      {this.dressList.map(eachItem => {
                        return (
                          <ItemPicker
                            size="140"
                            circleOrSquare="square"
                            label={eachItem["label"]}
                            img={eachItem["img"]}
                            onClick={(newState, itemLabel) => {
                              if (newState) {
                                this.dressPreference.push(itemLabel);
                              } else {
                                this.dressPreference.splice(
                                  this.dressPreference.indexOf(itemLabel),
                                  1
                                );
                              }
                              this.setState({
                                dressPreference:
                                  this.dressPreference.join(", "),
                              });
                            }}
                          />
                        );
                      })}
                    </div>
                    {this.state.dressPreference !== "" ? (
                      <div className="selected_items">
                        <b>Selected:</b> {this.state.dressPreference}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="input-parent multiple-choice">
                    <div className="survey-question">
                      {this.state.currentLang.s_pants}
                    </div>

                    <div className="column_4_list">
                      {this.pantsList.map(eachItem => {
                        return (
                          <ItemPicker
                            size="140"
                            circleOrSquare="square"
                            label={eachItem["label"]}
                            img={eachItem["img"]}
                            onClick={(newState, itemLabel) => {
                              if (newState) {
                                this.pantsPreference.push(itemLabel);
                              } else {
                                this.pantsPreference.splice(
                                  this.pantsPreference.indexOf(itemLabel),
                                  1
                                );
                              }
                              this.setState({
                                pantsPreference:
                                  this.pantsPreference.join(", "),
                              });
                            }}
                          />
                        );
                      })}
                    </div>
                    {this.state.pantsPreference !== "" ? (
                      <div className="selected_items">
                        <b>Selected:</b> {this.state.pantsPreference}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="input-parent multiple-choice">
                    <div className="survey-question">
                      {this.state.currentLang.s_jeans}
                    </div>

                    <div
                      className="column_4_list"
                      style={{
                        gridTemplateColumns: "1fr 1fr 1fr",
                        maxWidth: "610px",
                      }}>
                      {this.jeansList.map(eachItem => {
                        return (
                          <ItemPicker
                            size="140"
                            circleOrSquare="square"
                            label={eachItem["label"]}
                            img={eachItem["img"]}
                            onClick={(newState, itemLabel) => {
                              if (newState) {
                                this.jeansPreference.push(itemLabel);
                              } else {
                                this.jeansPreference.splice(
                                  this.jeansPreference.indexOf(itemLabel),
                                  1
                                );
                              }
                              this.setState({
                                jeansPreference:
                                  this.jeansPreference.join(", "),
                              });
                            }}
                          />
                        );
                      })}
                    </div>
                    {this.state.jeansPreference !== "" ? (
                      <div className="selected_items">
                        <b>Selected:</b> {this.state.jeansPreference}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="survey-center-item" style={{ maxWidth: "1000px" }}>
              <div className="full-width dis-content">
                <div className="all-input">
                  <div className="input-parent no-border text-start question-padding">
                    <div className="survey-question">
                      {ReactHtmlParser(this.state.currentLang.s_upload)}
                    </div>

                    <div className="div-example-img">
                      <img src={Model1} alt="Portait1" />

                      <img src={Model2} alt="Portrait2" />
                    </div>
                    <div className="m-a-m">
                      <div className="survey-portrait-images-content">
                        {Object.keys(this.state.portraits).length > 0 ? (
                          <div className="survey-portrait-images-box">
                            {Object.keys(this.state.portraits).map(
                              eachPortrait => {
                                return (
                                  <div className="survey-portrait-parent">
                                    <img
                                      className="survey-portrait"
                                      src={this.state.portraits[eachPortrait]}
                                      alt="portrait"
                                    />
                                    <img
                                      onClick={() => {
                                        var currentPortraits =
                                          this.state.portraits;
                                        delete currentPortraits[eachPortrait];
                                        this.updateState({
                                          portraits: currentPortraits,
                                        });
                                      }}
                                      className="remove-portrait-icon"
                                      src={RemovePortraitsIcon}
                                      alt="remove"
                                    />
                                  </div>
                                );
                              }
                            )}
                          </div>
                        ) : (
                          <div className="center-text">
                            {ReactHtmlParser(this.state.currentLang.s_upload)}
                          </div>
                        )}
                      </div>
                      <DragAndDropImageUploader
                        parentCallback={s => {
                          if (Object.keys(this.state.portraits).length < 5) {
                            var currentPortraits = this.state.portraits;
                            this.uploadCount += 1;
                            currentPortraits["_" + this.uploadCount] = s;
                            this.updateState({ portraits: currentPortraits });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="isolate full-width dis-content">
              <div className="all-input">
                <div className="input-parent">
                  <ul className="ul-input">
                    <li className="li-input-title">
                      <div className="input-title">
                        {this.state.currentLang.s_referral}
                      </div>
                    </li>
                    <li className="li-input-input">
                      <input
                        type="text"
                        placeholder=""
                        value={this.state.referralCode}
                        onInput={e =>
                          this.setState({ referralCode: e.currentTarget.value })
                        }
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {this.state.showError ? (
              <div className="error margin-bot-s">
                {this.state.currentLang.s_error_fill}
              </div>
            ) : null}
            {this.state.showReturnError ? (
              <div className="error margin-bot-s">
                {this.state.currentLang.s_error_correct}
              </div>
            ) : null}
            {this.state.emailError ? (
              <div className="error margin-bot-s">
                {this.state.currentLang.s_error_email}
              </div>
            ) : null}

            {this.state.noneSelectedError ? (
              <div className="error margin-bot-s">
                {this.state.currentLang.s_error_product}
              </div>
            ) : null}
            {this.state.portraitsError ? (
              <div className="error margin-bot-s">
                {ReactHtmlParser(this.state.currentLang.s_upload)}
              </div>
            ) : null}

            <div
              className="hori center-everything"
              style={{ margin: "1em 0 2.5em 0" }}>
              <img
                className="check-btn"
                alt="checl-btn"
                onClick={() => {
                  this.setState({
                    agree: !this.state.agree,
                  });
                }}
                src={this.state.agree ? Checked : Unchecked}
              />
              <div
                className="pad-l answer"
                onClick={() => {
                  this.setState({
                    agree: !this.state.agree,
                  });
                }}>
                {this.state.currentLang.s_consent}
              </div>
            </div>
            <div className="margin-bot">
              <div
                className="black-button"
                onClick={() => {
                  this.submit();
                }}>
                {this.state.currentLang.s_submit}
              </div>
            </div>

            <Footer />
          </div>
        </div>
        {/* 
        <div className="parent" onClick={this.doSomething}>
          {this.state.loading ? (
            <div className="div_loading">
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-45px",
                  marginLeft: "-45px",
                }}>
                <ReactLoading type="cylon" color="#666666" width="90px" />
              </div>
            </div>
          ) : null}
        </div> */}
      </div>
    );
  }
}

export default MainPage;
