import "./App.css";
import MainPage from "./Pages/MainPage";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import ThankYouPage from "./Pages/ThankYouPage";
import SurveyPage from "./Pages/SurveyPage";
import CheckOutPage from "./Pages/CheckOutPage";
import AdminLoginPage from "./Pages/AdminLoginPage";
import DashboardPage from "./Pages/DashboardPage";
import ReplyResponsePage from "./Pages/ReplyResponsePage";
import MainSeason2Page from "./Pages/MainSeason2Page";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/details" exact element={<MainSeason2Page />} />
          <Route path="/" exact element={<MainPage />} />
          <Route path="/survey" exact element={<SurveyPage />} />
          <Route path="/thankyou" exact element={<ThankYouPage />} />
          <Route path="/checkout" exact element={<CheckOutPage />} />
          <Route path="/adminlogin" exact element={<AdminLoginPage />} />
          <Route path="/dashboard" exact element={<DashboardPage />} />
          <Route path="/replyresponse" exact element={<ReplyResponsePage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
