import "./CartItem.css";
import Checked from "../Images/checked.svg";
import Unchecked from "../Images/unchecked.svg";
import React from "react";
import PropTypes from 'prop-types';

class CartItem extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            checked: false
        }
    }

    componentDidMount()
    {
        // console.log(this.props.cartInfo.price)
    }

    render()
    {
        return (
            <div
                className="cart-parent"
                style={{
                flexDirection: this.props.mobileMode
                    ? "column"
                    : "row"
            }}>
                <div className="thumbnail-parent">
                    <img
                        className={"cart-thumbnail" + (this.props.containMode ? " contain" : "")}
                        src={this.props.cartInfo.photo}
                        alt="cart item thumbnail"/>
                </div>
                <div
                    className="cart-details"
                    style={{
                    width: this.props.mobileMode
                        ? "80%"
                        : 350
                }}>
                    <div className="text-name">{this.props.cartInfo.title}</div>
                    <div className="text-sku">{this.props.cartInfo.sku}</div>
                    <div className="text-colour-size">{this.props.cartInfo.desc}</div>
                    <div className="text-details">{this.props.cartInfo.small_desc}</div>
                    <div className="text-price">RM {(this.props.cartInfo.price)}</div>
                </div>
                <img
                    className="check-btn"
                    alt="check-btn"
                    onClick={() => {
                    this
                        .props
                        .onSelectionChanged(this.props.cartInfo.id)
                }}
                    src={this.props.forceCheck
                    ? Checked
                    : this.props.cartInfo.checked
                        ? Checked
                        : Unchecked}/>
            </div>
        );
    }
}

CartItem.propTypes = {
    cartInfo: PropTypes.object,
    onSelectionChanged: PropTypes.func,
    mobileMode: PropTypes.bool,
    forceCheck: PropTypes.bool,
    containMode: PropTypes.bool
};

CartItem.defaultProps = {
    cartInfo: {
        id: 0,
        name: "Name",
        sku: "SKU",
        colour: "Red",
        size: "M",
        details: "2% cottom 98% love",
        price: 99.9,
        img: "https://try.whattowear.com.my/img/showcase_a.jpg",
        checked: false
    },
    onSelectionChanged: (id) => {},
    mobileMode: false,
    forceCheck: false,
    containMode: false
};

export default CartItem;