import React from "react";
import Logo from "../assets/images/logo.png";
import Step1 from "../assets/images/step1.svg";
import Step2 from "../assets/images/step2.svg";
import Step3 from "../assets/images/step3.svg";
import NextButtonIcon from "../assets/images/next_button_icon.svg";
import PrevButtonIcon from "../assets/images/prev_button_icon.svg";
import Slider from "@ant-design/react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./MainSeason2Page.css";
import { InfinitySpin } from "react-loader-spinner";
import VideoA from "../Videos/vid_1.mp4";
import VideoAPoster from "../Images/video_a_poster.png";
import PlayButton from "../Images/play.svg";
import PauseButton from "../Images/pause.svg";

class MainSeason2Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      width: 0,
    };

    this.steps = [
      {
        image: Step1,
        title: "Tell us about you",
        description:
          "Take a quick and easy questionare to help us learn about you and what you love",
      },
      {
        image: Step2,
        title: "Get pieces picked just for you",
        description:
          "Consult with our stylist to pick on pieces that reflect your style, fit and price point.",
      },
      {
        image: Step3,
        title: "Receive Clothings At Your Door-step",
        description:
          "Try & keep what you like, pay for what you picked, and return the rest to us.",
      },
    ];

    this.playVideo = this.playVideo.bind(this);
    this._isMounted = false;
    this.updateDimensions = this.updateDimensions.bind(this);
    this.tryNow = this.tryNow.bind(this);
  }

  componentDidMount() {
    this.getCarouselImages();
    this._isMounted = true;
    // listen to resize event
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState(state) {
    if (this._isMounted) {
      this.setState(state);
    }
  }

  carouselShowItemCount() {
    // console.log(this.state.width, 320);
    return Math.max(1, Math.floor(Math.max(this.state.width, 1) / 320));
  }

  playVideo(vid) {
    var state = false;
    if (vid === 1) {
      state = !this.state.vid1Playing;
      this.updateState({ vid1Playing: state });
    }
    if (vid === 2) {
      state = !this.state.vid2Playing;
      this.updateState({ vid2Playing: state });
    }
    var vidElement = window.document.getElementById("vid_" + vid);
    if (state) {
      vidElement.play();
      window.sendTracking("video" + vid, "play", "1");
    } else {
      vidElement.pause();
    }
  }

  async getCarouselImages() {
    let response = await fetch(
      "https://try.whattowear.com.my/img/season2/get_image_carousel_2.php"
    );
    let json = await response.json();
    // repeat json.data x times to make it longer, at least make it has 10 items

    let images = json.data;
    let repeat = 10;
    while (repeat > 0) {
      images = images.concat(json.data);
      repeat--;
    }
    this.updateState({ images: images });
  }

  tryNow() {
    var url = "https://try.whattowear.com.my/details";
    window.location.href = url;
  }

  contactNow() {
    var url = "https://api.whatsapp.com/send/?phone=60136838383";
    window.location.href = url;
  }

  render() {
    return (
      <div className="landing-page-parent">
        <div className="landing-page-top-bar">
          <a href="https://www.whattowear.com.my">
            <img style={{ height: 48 }} src={Logo} alt="Logo" />
          </a>
        </div>

        <div className="landing-page-title-bar">
          <span style={{ color: "#333333" }}>
            <a
              style={{
                color: "unset",
                border: "unset",
                textDecoration: "unset",
              }}
              href="https://www.whattowear.com.my">
              Home /
            </a>
          </span>
          <span style={{ color: "#6c757d" }}>Try At Home</span>
        </div>

        <div className="landing-page-main">
          <div className="landing-page-main-title">Try-At-Home</div>
          <div className="landing-page-main-subtitle">
            We provide Personal Styling Consultation <br />
            Receive& Try Outfits at Your Door-step, For Free. <br />
          </div>
          <div style={{ margin: "1em" }}>
            <div className="landing-page-try-now-button" onClick={this.tryNow}>
              Try Now
            </div>
          </div>
          <div className="video-parent" onClick={() => this.playVideo(1)}>
            <video
              className="video-wide"
              playsInline
              poster={VideoAPoster}
              id="vid_1">
              <source src={VideoA} type="video/mp4" />
            </video>
            <img
              className={
                this.state.vid1Playing ? "pause-button" : "play-button"
              }
              src={this.state.vid1Playing ? PauseButton : PlayButton}
              alt="play-pause-button"
            />
          </div>
        </div>

        <div className="landing-page-steps">
          <div className="landing-page-title m-b-l">How Try-At-Home works</div>

          <div className="steps-parent">
            {this.steps.map((step, index) => {
              return (
                <div key={`step-${index}`} className="step-container">
                  <div className="step-image">
                    <img src={step.image} alt="" />
                  </div>
                  <div>
                    <div className="step-title">{step.title}</div>
                    <div className="step-description">{step.description}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ margin: "4em" }}>
            <div className="landing-page-try-now-button" onClick={this.tryNow}>
              Try Now
            </div>
          </div>
        </div>

        <div className="landing-page-customization">
          <div className="landing-page-title" style={{ paddingBottom: "1em" }}>
            Tell us what you like <br />
            we will find what you love
          </div>

          <div className="landing-page-main-subtitle">
            We provide Personal Styling Consultation <br />
            Receive& Try Outfits at Your Door-step, For Free. <br />
          </div>

          <div className="landing-page-customization-image-container">
            <div style={{ width: "100vw" }}>
              {this.state.images.length > 0 ? (
                <Slider
                  dots={false}
                  arrows={true}
                  infinite={true}
                  speed={750}
                  autoplaySpeed={5000}
                  autoplay={true}
                  slidesToShow={this.carouselShowItemCount()}
                  slidesToScroll={1}
                  pauseOnHover={false}
                  nextArrow={
                    <div style={{ width: 48, height: 48 }}>
                      <img src={NextButtonIcon} alt="" />
                    </div>
                  }
                  prevArrow={
                    <div style={{ width: 48, height: 48 }}>
                      <img src={PrevButtonIcon} alt="" />
                    </div>
                  }>
                  {this.state.images.map((image, index) => (
                    <div
                      className="customization-image-container"
                      style={{ objectFit: "cover" }}
                      key={index}>
                      <img className="customization-image" src={image} alt="" />
                    </div>
                  ))}
                </Slider>
              ) : (
                <InfinitySpin color="#dfcaba" />
              )}
            </div>
          </div>
        </div>

        <div className="landing-page-questionaire">
          <div className="landing-page-title" style={{ paddingBottom: "1em" }}>
            Having trouble with
            <br />
            filling up the questionaire?
          </div>

          <div className="landing-page-main-subtitle">
            No Worries, Schedule a 5-minutes call with
            <br />
            out Try-At-Home Stylist, we will get in touch soon <br />
          </div>

          <div style={{ margin: "3em 2em" }}>
            <div
              className="landing-page-try-now-button"
              onClick={this.contactNow}>
              Schedule Call
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainSeason2Page;
