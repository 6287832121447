import React from "react";
import sha1 from "js-sha1";
// import queryString from 'query-string';
import "./AdminLoginPage.css";
import Logo from "../Images/wtw_logo.png";
import { TextField } from "@mui/material";
import { login } from "../Utilities/Requests";
import withRouter from "../Utilities/withRouter";

class AdminLoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
    };

    this.performLogin = this.performLogin.bind(this);
  }

  performLogin() {
    this.callLoginAPI();
  }

  async callLoginAPI() {
    let result = await login(
      this.state.username,
      sha1(this.state.username + this.state.password)
    );
    if (result.status === 0) {
      this.setState({ showError: true });
    }
    if (result.status === 1) {
      localStorage.setItem("j", result["jwt"]);
      this.props.router.navigate("/dashboard");
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div className="admin-login-parent">
        <div className="admin-login-content">
          <img className="logo" src={Logo} alt="logo" />
          <TextField
            className="text-field"
            label="Username"
            variant="outlined"
            value={this.state.username}
            onChange={e => {
              this.setState({ username: e.target.value });
            }}
          />
          <TextField
            className="text-field"
            label="Password"
            variant="outlined"
            type="password"
            value={this.state.password}
            onChange={e => {
              this.setState({ password: e.target.value });
            }}
          />
          <div className="login-btn" onClick={this.performLogin}>
            Login
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AdminLoginPage);
