import React from "react";
import "./SurveyPage.css";
import "./common.css";
import Deco from "../Images/deco.png";
import Logo from "../Images/logo.svg";
import NameIcon from "../Images/name_icon.svg";
import Checked from "../Images/checked.svg";
import Unchecked from "../Images/unchecked.svg";
import DressIcon from "../Images/dress.png";
import BodyMeasurementIcon from "../Images/body_measurement_icon.svg";
import RemovePortraitsIcon from "../Images/remove_portraits_icon.svg";
import FBIcon from "../Images/fb_icon.svg";
import IGIcon from "../Images/ig_icon.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Tooltip } from "@mui/material";
import Footer from "../Components/Footer";
import { generateID, surveyInsert } from "../Utilities/Requests";
import { TailSpin } from "react-loader-spinner";
import { MdOutlineHelpOutline } from "react-icons/md";
import DragAndDropImageUploader from "../Components/DragAndDropImageUploader";
import ColorPicker from "../Components/ColorPicker";

class SurveyPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileMode: false,
      hijab: true,
      design: 0,
      topAnchor: null,
      bottomAnchor: null,
      bodyTypeAnchor: null,
      skinToneAnchor: null,
      top: 0,
      bottom: 0,
      bodyType: 0,
      skinTone: 0,
      wishToReceive: [],
      workCasual: 0,
      name: "",
      ic: "",
      dob: "",
      phone: "",
      email: "",
      address: "",
      ig: "",
      fb: "",
      occupation: "",
      height: "",
      weight: "",
      shoulder: "",
      bust: "",
      underbust: "",
      waist: "",
      hips: "",
      thigh: "",
      agree: false,
      showError: false,
      loading: false,
      showReturnError: false,
      emailError: false,
      noneSelectedError: false,
      portraitsError: false,
      portraits: {},
    };

    this.neverWearColor = [];
    this.uploadCount = 0;
    this.colorList = [
      "Black",
      "White",
      "Grey",
      "Gold",
      "Beige",
      "Brown",
      "Red",
      "Orange",
      "Yellow",
      "Green",
      "Teal",
      "Blue",
    ];
    this.colorCodeList = [
      "#000",
      "#fff",
      "#848484",
      "#c9a907",
      "#f5f5db",
      "#54260e",
      "#ba1e20",
      "#f9a700",
      "#ffff00",
      "#008000",
      "#008081",
      "#000080",
    ];
    this.sizeList = [
      "Please Select Size",
      "XS",
      "S",
      "M",
      "L",
      "XL",
      "XXL",
      "XXXL",
    ];
    this.wishToReceiveList = [
      "Dress",
      "Skirt",
      "Palazzo",
      "Baju Kurung",
      "Blouse",
      "Tunic Shirt",
      "Shirt",
    ];
    this.workCasualList = ["Work", "Casual"];
    this.bodyTypeList = [
      "Inverted triangle",
      "Pear",
      "Round",
      "Rectangle",
      "Hourglass",
    ];
    this.skinToneList = ["Fair", "Olive", "Tan", "Brown", "Dark Brown"];
    this.wearColorList = [
      { color: "black", label: "Black", textColor: "white" },
      { color: "white", label: "White", textColor: "#787a7c" },
      { color: "#787a7c", label: "Grey", textColor: "white" },
      { color: "#c8ab0e", label: "Gold", textColor: "white" },
      { color: "#f5f5db", label: "Beige", textColor: "#7a7a7f" },
      { color: "#55250f", label: "Brown", textColor: "white" },
      { color: "#b90a21", label: "Red", textColor: "white" },
      { color: "#f7a805", label: "Orange", textColor: "white" },
      { color: "#ffff07", label: "Yellow", textColor: "#797a7d" },
      { color: "#128200", label: "Green", textColor: "white" },
      { color: "#118281", label: "Teal", textColor: "white" },
      { color: "#000080", label: "Blue", textColor: "white" },
    ];
    this._isMounted = false;
    this.googleForm = React.createRef();
    this.sent25 = false;
    this.sent50 = false;
    this.sent75 = false;
    this.sent100 = false;

    this.openTopSize = this.openTopSize.bind(this);
    this.closeTopSize = this.closeTopSize.bind(this);
    this.openBottomSize = this.openBottomSize.bind(this);
    this.closeBottomSize = this.closeBottomSize.bind(this);
    this.openBodyType = this.openBodyType.bind(this);
    this.closeBodyType = this.closeBodyType.bind(this);
    this.openSkinTone = this.openSkinTone.bind(this);
    this.closeSkinTone = this.closeSkinTone.bind(this);
    this.handleWishToReceive = this.handleWishToReceive.bind(this);
    this.handleWorkCasual = this.handleWorkCasual.bind(this);
    this.checkSubmit = this.checkSubmit.bind(this);
    this.checkString = this.checkString.bind(this);
    this.checkInt = this.checkInt.bind(this);
    this.submit = this.submit.bind(this);
    this.listenToScroll = this.listenToScroll.bind(this);
  }

  openTopSize = event => {
    this.setState({ topAnchor: event.currentTarget });
  };

  closeTopSize(selectedItem) {
    if (selectedItem >= 0) {
      this.updateState({ topAnchor: null, top: selectedItem });
    } else {
      this.updateState({ topAnchor: null });
    }
  }

  openBottomSize = event => {
    this.setState({ bottomAnchor: event.currentTarget });
  };

  closeBottomSize(selectedItem) {
    if (selectedItem >= 0) {
      this.updateState({ bottomAnchor: null, bottom: selectedItem });
    } else {
      this.updateState({ bottomAnchor: null });
    }
  }

  openBodyType = event => {
    this.setState({ bodyTypeAnchor: event.currentTarget });
  };

  closeBodyType(selectedItem) {
    if (selectedItem >= 0) {
      this.updateState({ bodyTypeAnchor: null, bodyType: selectedItem });
    } else {
      this.updateState({ bodyTypeAnchor: null });
    }
  }

  openSkinTone = event => {
    this.setState({ skinToneAnchor: event.currentTarget });
  };

  closeSkinTone(selectedItem) {
    if (selectedItem >= 0) {
      this.updateState({ skinToneAnchor: null, skinTone: selectedItem });
    } else {
      this.updateState({ skinToneAnchor: null });
    }
  }

  updateState(state) {
    if (this._isMounted) {
      this.setState(state);
    }
  }

  handleResize = e => {
    this.updateState({
      mobileMode: window.innerWidth < 650,
      currentWidth: window.innerWidth,
      currentHeight: window.innerHeight,
    });
  };

  handleWishToReceive(i) {
    let currentWishToReceive = this.state.wishToReceive;

    if (currentWishToReceive[i] === 1) {
      currentWishToReceive[i] = 0;
      this.updateState({ wishToReceive: currentWishToReceive });
    } else {
      currentWishToReceive[i] = 1;
      this.updateState({ wishToReceive: currentWishToReceive });
    }
    console.log("wishtoreceive" + JSON.stringify(this.state.wishToReceive));
  }
  handleWorkCasual(i) {
    let currentSelection = this.state.workCasual;

    if (currentSelection[i] === 1) {
      currentSelection[i] = 0;
      this.updateState({ workCasual: currentSelection });
    } else {
      currentSelection[i] = 1;
      this.updateState({ workCasual: currentSelection });
    }
    console.log("workcasual" + JSON.stringify(this.state.workCasual));
  }
  cleanString(s) {
    return s.replace(/[^a-zA-Z ]/g, "");
  }

  componentDidMount() {
    this._isMounted = true;
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    window.sendTracking("enter", "page", "survey");

    window.addEventListener("scroll", this.listenToScroll);
    window.fb_pixel_track_survey();
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    if (scrolled > 0.95) {
      if (!this.sent100) {
        window.sendTracking("scroll-survey-100", "survey", "100%");
        this.sent100 = true;
      }
    } else if (scrolled > 0.75) {
      if (!this.sent75) {
        window.sendTracking("scroll-survey-75", "survey", "75%");
        this.sent75 = true;
      }
    } else if (scrolled > 0.5) {
      if (!this.sent50) {
        window.sendTracking("scroll-survey-50", "survey", "50%");
        this.sent50 = true;
      }
    } else if (scrolled > 0.25) {
      if (!this.sent25) {
        window.sendTracking("scroll-survey-25", "survey", "25%");
        this.sent25 = true;
      }
    }
  };

  checkSubmit() {
    let toCheck = [
      this.state.name,
      this.state.ic,
      this.state.dob,
      this.state.phone,
      this.state.email,
      this.state.address,
      this.state.ig,
      this.state.fb,
      this.state.occupation,
      this.state.height,
      this.state.weight,
      this.state.shoulder,
      this.state.bust,
      this.state.waist,
      this.state.hips,
    ];
    var pass = true;
    if (this.state.top === 0 || this.state.bottom === 0) {
      pass = false;
    }
    toCheck.forEach(eachToCheck => {
      if (!this.checkString(eachToCheck)) {
        pass = false;
        return false;
      }
    });
    var noneSelectedError = false;
    if (
      this.state.wishToReceive.length === 0 ||
      !this.state.wishToReceive.includes(1)
    ) {
      pass = false;
      noneSelectedError = true;
    }

    var emailError = !this.validateEmail(this.state.email);
    if (emailError) {
      pass = false;
    }
    if (!this.state.agree) {
      pass = false;
    }
    var portraitsError = false;

    if (Object.keys(this.state.portraits).length < 2) {
      portraitsError = true;
      pass = false;
    }
    if (!pass) {
      this.setState({
        showError: true,
        emailError: emailError,
        noneSelectedError: noneSelectedError,
        portraitsError: portraitsError,
      });
    }
    return pass;
  }

  checkString(s) {
    return s.replace(" ", "") !== "";
  }

  checkInt(i) {
    return /^\d+$/.test(i);
  }

  intOnly(i) {
    return i.replace(/[^\d.-]/g, "");
  }

  validateEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  async submit() {
    if (this.checkSubmit()) {
      this.setState({ loading: true });

      var selectedWishToReceiveArray = this.state.wishToReceive;
      var newSelectedArray = [];
      var selectedWishToReceive = "";
      for (var i = 0; i < selectedWishToReceiveArray.length; i++) {
        if (selectedWishToReceiveArray[i] === 1) {
          newSelectedArray.push(this.wishToReceiveList[i]);
        }
      }
      selectedWishToReceive = newSelectedArray.join(", ");

      var portrait = [];
      Object.keys(this.state.portraits).forEach(eachPortrait => {
        portrait.push(this.state.portraits[eachPortrait]);
      });
      var selectedDesign = "Printed";
      switch (this.state.design) {
        default:
        case 0:
          selectedDesign = "Printed";
          break;
        case 1:
          selectedDesign = "Plain";
          break;
        case 2:
          selectedDesign = "Both";
          break;
      }
      var selectedWorkCasual = "Work";
      switch (this.state.workCasual) {
        default:
        case 0:
          selectedWorkCasual = "Work";
          break;
        case 1:
          selectedWorkCasual = "Casual";
          break;
        case 2:
          selectedWorkCasual = "Both";
          break;
      }
      let surveyData = {
        name: this.state.name,
        nric: this.state.ic,
        dob: this.state.dob,
        phone: this.state.phone,
        address: this.state.address,
        email: this.state.email,
        ig_id: this.state.ig,
        fb_id: this.state.fb,
        occupation: this.state.occupation,
        style_pref: this.state.hijab ? "Hijab" : "Non-Hijab",
        design: selectedDesign,
        height: this.state.height,
        weight: this.state.weight,
        shoulder: this.state.shoulder,
        bust: this.state.bust,
        waist: this.state.waist,
        hips: this.state.hips,
        size_top: this.sizeList[this.state.top],
        size_bottom: this.sizeList[this.state.bottom],
        wish_to_receive: selectedWishToReceive,
        work_casual: selectedWorkCasual,
        agreement: true,
        portrait: portrait,
        never_wear_color: this.neverWearColor,
      };

      console.log("surveyData :" + JSON.stringify(surveyData));

      let id = generateID(9);
      let redirect = `https://try.whattowear.com.my/survey/pay/pay.php?r=${id}`;
      var result = await surveyInsert(id, surveyData);
      if (result.status === true) {
        window.sendTracking("navigate", "payment", "1");
        window.location.href = redirect;
      } else {
        this.setState({ loading: false, showReturnError: true });
      }
    }
  }

  render() {
    return (
      <div className="parent dis-content">
        <img className="deco flip-h" src={Deco} alt="deco" />
        <div className="content">
          <img className="logo" src={Logo} alt="logo" />

          <div className="display-title">Let's get started!</div>
          <div className="isolate survey-center-item">
            <div className="mini-highlight-text">
              <img className="input-icon" src={NameIcon} alt="icon" />
              <div>A. Personal Details</div>
            </div>
            <div className="isolate full-width dis-content">
              <div className="all-input">
                <div className="input-parent">
                  <div className="input-title">
                    <input
                      type="text"
                      placeholder="1. Name *"
                      value={this.state.name}
                      onInput={e =>
                        this.setState({ name: e.currentTarget.value })
                      }
                    />
                  </div>
                </div>
                <div className="input-parent">
                  <div className="input-title">
                    <input
                      type="text"
                      placeholder="2. IC Number *"
                      value={this.state.ic}
                      onInput={e =>
                        this.setState({ ic: e.currentTarget.value })
                      }
                    />
                  </div>
                </div>
                <div className="input-parent">
                  <div className="input-title">
                    <input
                      type="text"
                      placeholder="3. Date of Birth *"
                      value={this.state.dob}
                      onInput={e =>
                        this.setState({ dob: e.currentTarget.value })
                      }
                    />
                  </div>
                </div>
                <div className="input-parent">
                  <div className="input-title">
                    <input
                      type="text"
                      placeholder="4. Phone Number *"
                      value={this.state.phone}
                      onInput={e =>
                        this.setState({ phone: e.currentTarget.value })
                      }
                    />
                  </div>
                </div>
                <div className="input-parent">
                  <div className="input-title">
                    <input
                      type="text"
                      placeholder="5. E-mail *"
                      value={this.state.email}
                      onInput={e =>
                        this.setState({ email: e.currentTarget.value })
                      }
                    />
                  </div>
                </div>
                <div className="input-parent">
                  <div className="input-title">
                    <input
                      type="text"
                      placeholder="6. Address *"
                      value={this.state.address}
                      onInput={e =>
                        this.setState({ address: e.currentTarget.value })
                      }
                    />
                  </div>
                </div>
                <div className="input-parent">
                  <div className="input-title">
                    <input
                      type="text"
                      placeholder="7. Instagram ID *"
                      value={this.state.ig}
                      onInput={e =>
                        this.setState({ ig: e.currentTarget.value })
                      }
                    />
                    <Tooltip
                      title="Click into your IG Profile and it’s on the top!"
                      enterTouchDelay={0}>
                      <IconButton>
                        <MdOutlineHelpOutline />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                <div className="input-parent">
                  <div className="input-title">
                    <input
                      type="text"
                      placeholder="8. Facebook Profile Link *"
                      value={this.state.fb}
                      onInput={e =>
                        this.setState({ fb: e.currentTarget.value })
                      }
                    />
                    <Tooltip
                      title="Open your FB profile > click Menu > Scroll to Bottom > Copy Link"
                      enterTouchDelay={0}>
                      <IconButton>
                        <MdOutlineHelpOutline />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                <div className="input-parent">
                  <div className="input-title">
                    <input
                      type="text"
                      placeholder="9. Occupation *"
                      value={this.state.occupation}
                      onInput={e =>
                        this.setState({ occupation: e.currentTarget.value })
                      }
                    />
                  </div>
                </div>
                <div className="input-parent no-border text-start question-padding">
                  <div className="survey-question">10. Style Preference *</div>
                  <div className="hori center-everything pad-l">
                    <img
                      className="check-btn"
                      alt="check-btn"
                      onClick={() => {
                        this.setState({ hijab: true });
                      }}
                      src={this.state.hijab ? Checked : Unchecked}
                    />
                    <div className="answer">Hijab</div>
                  </div>
                  <div className="hori center-everything pad-l">
                    <img
                      alt="check-btn"
                      className="check-btn"
                      onClick={() => {
                        this.setState({ hijab: false });
                      }}
                      src={!this.state.hijab ? Checked : Unchecked}
                    />
                    <div className="answer">Non-Hijab</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="survey-center-item">
            <div className="mini-highlight-text ">
              <img
                className="input-icon"
                src={BodyMeasurementIcon}
                alt="icon"
              />
              <div>B. Body Measurement</div>
            </div>
            <div className="isolate full-width dis-content">
              <div className="all-input">
                <div className="input-parent">
                  <div className="input-title">
                    <input
                      type="text"
                      placeholder="1. Height (cm) *"
                      value={this.state.height}
                      onInput={e =>
                        this.setState({
                          height: this.intOnly(e.currentTarget.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-parent">
                  <div className="input-title">
                    <input
                      type="text"
                      placeholder="2. Weight (kg) *"
                      value={this.state.weight}
                      onInput={e =>
                        this.setState({
                          weight: this.intOnly(e.currentTarget.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-parent">
                  <div className="input-title">
                    <input
                      type="text"
                      placeholder="3. Shoulder (inches) *"
                      value={this.state.shoulder}
                      onInput={e =>
                        this.setState({
                          shoulder: this.intOnly(e.currentTarget.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-parent">
                  <div className="input-title">
                    <input
                      type="text"
                      placeholder="4. Bust (inches) *"
                      value={this.state.bust}
                      onInput={e =>
                        this.setState({
                          bust: this.intOnly(e.currentTarget.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-parent">
                  <div className="input-title">
                    <input
                      type="text"
                      placeholder="5. Waist (inches) *"
                      value={this.state.waist}
                      onInput={e =>
                        this.setState({
                          waist: this.intOnly(e.currentTarget.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="input-parent">
                  <div className="input-title">
                    <input
                      type="text"
                      placeholder="6. Hips (inches) *"
                      value={this.state.hips}
                      onInput={e =>
                        this.setState({
                          hips: this.intOnly(e.currentTarget.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div
                  className="input-parent no-border text-start question-padding"
                  style={{
                    minWidth: 350,
                  }}>
                  <div className="survey-question">7. Size *</div>
                  <div className="top-btm-list hori">
                    <div className="top-btm">Top :</div>
                    <div className="btn-wrap">
                      <div className="dropdown-btn" onClick={this.openTopSize}>
                        {this.sizeList[this.state.top]}
                      </div>
                      <Menu
                        anchorEl={this.state.topAnchor}
                        open={Boolean(this.state.topAnchor)}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}>
                        {this.sizeList.map((eachSize, i) => (
                          <MenuItem
                            key={"key" + i}
                            onClick={() => {
                              this.closeTopSize(i);
                            }}>
                            {eachSize}
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </div>
                  <div className="top-btm-list hori">
                    <div className="top-btm">Bottom :</div>
                    <div className="btn-wrap">
                      <div
                        className="dropdown-btn"
                        onClick={this.openBottomSize}>
                        {this.sizeList[this.state.bottom]}
                      </div>
                      <Menu
                        anchorEl={this.state.bottomAnchor}
                        open={Boolean(this.state.bottomAnchor)}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}>
                        {this.sizeList.map((eachSize, i) => (
                          <MenuItem
                            key={"key" + i}
                            onClick={() => {
                              this.closeBottomSize(i);
                            }}>
                            {eachSize}
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="isolate survey-center-item"
            style={{ "max-width": "1000px" }}>
            <div className="mini-highlight-text ">
              <img className="input-icon" src={DressIcon} alt="icon" />
              <div>C. Style Preference</div>
            </div>
            <div className="isolate full-width dis-content">
              <div className="all-input">
                <div className="input-parent no-border text-start question-padding">
                  <div className="survey-question">
                    1. Which colors would you never wear? *
                  </div>
                  <div className="survey-hint">
                    Cross out the colors you would never wear. Can select more
                    than 1 option
                  </div>
                  <div className="color-list">
                    {this.wearColorList.map(eachColor => {
                      return (
                        <ColorPicker
                          size="100px"
                          label={eachColor["label"]}
                          color={eachColor["color"]}
                          textColor={eachColor["textColor"]}
                          onClick={(newState, color) => {
                            if (newState) {
                              this.neverWearColor.push(color);
                            } else {
                              this.neverWearColor.splice(
                                this.neverWearColor.indexOf(color),
                                1
                              );
                            }
                            console.log(this.neverWearColor);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>

                <div className="input-parent no-border text-start question-padding">
                  <div className="survey-question">
                    2. Which Design you most wearing? *
                  </div>
                  <div className="hori center-everything pad-l">
                    <img
                      className="check-btn"
                      alt="check-btn"
                      onClick={() => {
                        this.setState({ design: 0 });
                      }}
                      src={this.state.design === 0 ? Checked : Unchecked}
                    />
                    <div className="answer">Printed</div>
                  </div>
                  <div className="hori center-everything pad-l">
                    <img
                      className="check-btn"
                      alt="check-btn"
                      onClick={() => {
                        this.setState({ design: 1 });
                      }}
                      src={this.state.design === 1 ? Checked : Unchecked}
                    />
                    <div className="answer">Plain</div>
                  </div>
                  <div className="hori center-everything pad-l">
                    <img
                      className="check-btn"
                      alt="check-btn"
                      onClick={() => {
                        this.setState({ design: 2 });
                      }}
                      src={this.state.design === 2 ? Checked : Unchecked}
                    />
                    <div className="answer">Both</div>
                  </div>
                </div>

                <div className="input-parent no-border text-start question-padding">
                  <div className="survey-question">
                    3. Products that you wish to receive: *
                  </div>
                  <div className="survey-hint">
                    Can select more than 1 option
                  </div>
                  {this.wishToReceiveList.map((eachWishToReceive, i) => (
                    <div
                      className="hori center-everything pad-l"
                      key={"wtrkey" + i}>
                      <img
                        className={"check-btn wtrkey" + i}
                        alt={"check-btn state " + this.state.wishToReceive[i]}
                        onClick={() => {
                          this.handleWishToReceive(i);
                        }}
                        src={
                          this.state.wishToReceive[i] === 1
                            ? Checked
                            : Unchecked
                        }
                      />
                      <div className="answer">{eachWishToReceive}</div>
                    </div>
                  ))}
                </div>
                <div className="input-parent no-border text-start question-padding">
                  <div className="survey-question">
                    4. What kind of outfit would you like us to carry on: *
                  </div>
                  <div className="hori center-everything pad-l">
                    <img
                      className="check-btn"
                      alt="check-btn"
                      onClick={() => {
                        this.setState({ workCasual: 0 });
                      }}
                      src={this.state.workCasual === 0 ? Checked : Unchecked}
                    />
                    <div className="answer">Work</div>
                  </div>
                  <div className="hori center-everything pad-l">
                    <img
                      className="check-btn"
                      alt="check-btn"
                      onClick={() => {
                        this.setState({ workCasual: 1 });
                      }}
                      src={this.state.workCasual === 1 ? Checked : Unchecked}
                    />
                    <div className="answer">Casual</div>
                  </div>
                  <div className="hori center-everything pad-l">
                    <img
                      className="check-btn"
                      alt="check-btn"
                      onClick={() => {
                        this.setState({ workCasual: 2 });
                      }}
                      src={this.state.workCasual === 2 ? Checked : Unchecked}
                    />
                    <div className="answer">Both</div>
                  </div>
                </div>
                <div className="input-parent no-border text-start question-padding">
                  <div className="survey-question">13. Portrait Photos: *</div>
                  <div className="survey-hint">
                    Please upload at least 2 recent portrait photos to help us
                    hand-pick clothes for you
                  </div>
                  <div className="m-a-m">
                    <div className="survey-portrait-images-content">
                      {Object.keys(this.state.portraits).length > 0 ? (
                        <div className="survey-portrait-images-box">
                          {Object.keys(this.state.portraits).map(
                            eachPortrait => {
                              return (
                                <div className="survey-portrait-parent">
                                  <img
                                    className="survey-portrait"
                                    src={this.state.portraits[eachPortrait]}
                                    alt="portrait"
                                  />
                                  <img
                                    onClick={() => {
                                      var currentPortraits =
                                        this.state.portraits;
                                      delete currentPortraits[eachPortrait];
                                      this.updateState({
                                        portraits: currentPortraits,
                                      });
                                    }}
                                    className="remove-portrait-icon"
                                    src={RemovePortraitsIcon}
                                    alt="remove"
                                  />
                                </div>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        <div className="center-text">
                          Please upload at least 2 portraits to help us better
                          fit your style (up to 5)
                        </div>
                      )}
                    </div>
                    <DragAndDropImageUploader
                      parentCallback={s => {
                        if (Object.keys(this.state.portraits).length < 5) {
                          var currentPortraits = this.state.portraits;
                          this.uploadCount += 1;
                          currentPortraits["_" + this.uploadCount] = s;
                          this.updateState({ portraits: currentPortraits });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.showError ? (
            <div className="error margin-bot-s">
              Please make sure you have filled up all the required information
              and confirm that the information provided is true and accurate.
            </div>
          ) : null}
          {this.state.showReturnError ? (
            <div className="error margin-bot-s">
              Please make sure all the inputs have the correct information
              before proceed.
            </div>
          ) : null}
          {this.state.emailError ? (
            <div className="error margin-bot-s">
              Please make sure e-mail format is correct.
            </div>
          ) : null}

          {this.state.noneSelectedError ? (
            <div className="error margin-bot-s">
              Please select at least one product that you wish to receive.
            </div>
          ) : null}
          {this.state.portraitsError ? (
            <div className="error margin-bot-s">
              Please upload at least 2 recent portrait photos to help us
              hand-pick clothes for you.
            </div>
          ) : null}

          <div className="hori center-everything margin-bot">
            <img
              className="check-btn"
              alt="checl-btn"
              onClick={() => {
                this.setState({
                  agree: !this.state.agree,
                });
              }}
              src={this.state.agree ? Checked : Unchecked}
            />
            <div className="pad-l answer">
              I hereby confirm that the information provided is true and
              accurate.
            </div>
          </div>
          <div className="margin-bot">
            <div
              className="black-button"
              onClick={() => {
                this.submit();
              }}>
              SUBMIT
            </div>
          </div>
          <div className="margin-bot">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/fashionstoriesofficial">
              <img className="inline-icon" src={FBIcon} alt="fb" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/fashionstories.my/">
              <img className="inline-icon" src={IGIcon} alt="ig" />
            </a>
          </div>
          <Footer />
        </div>
        {this.state.loading ? (
          <div className="loading-bg">
            <TailSpin color="#fdf9f0" height={80} width={80} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default SurveyPage;
